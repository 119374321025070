import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function MainSlider() {
  const [sliders, setSliders] = useState([]); // State to hold slider data
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    centerMode: true,
    centerPadding: '0px',
    customPaging: function(i) {
      return <div className="custom-dot"></div>;
    },
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerPadding: '30px',
        },
      },
    ],
  };

  useEffect(() => {
    const fetchSliders = async () => {
      try {
        const response = await fetch('https://nofa365.com/api/sliders');
        const data = await response.json();

        // Filter active sliders with slider_type "Main"
        const activeMainSliders = data.filter(slider => slider.status === 'active' && slider.slider_type === 'Main');

        setSliders(activeMainSliders); // Set the filtered sliders to state
      } catch (error) {
        console.error('Error fetching sliders:', error);
      }
    };

    fetchSliders();
  }, []); // Empty dependency array to run only once on mount

  return (
    <div className="main-slider">
      <Slider {...settings}>
        {sliders.map((slider, index) => (
          <div key={index}>
            <img  src={`https://nofa365.com/storage/${slider.thubmnail}`} alt={`Slider ${index + 1}`} /> 
          </div>
        ))}
      </Slider>

      <div className='row announcement justify-content-center'>
        <div className='col-2 announcement-icon'>
          <img src='./icon/announcement-icon.svg' alt='announcement' />
        </div>
        <div className='col-10 announcement-text-wrapper'>
          <p className='announcement-text'>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
          </p>
        </div>
      </div>
    </div>
  );
}

export default MainSlider;
