import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faMessage } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

function Deposit() {
  const [activeItem, setActiveItem] = useState(''); // Selected payment method
  const [activeAmount, setActiveAmount] = useState(null); // Selected deposit amount
  const [items, setItems] = useState([]); // Payment methods
  const [promotions, setPromotions] = useState([]); // Promotions data
  const [selectedPromotion, setSelectedPromotion] = useState(null); // Selected promotion
  const [selectedPromotionId, setSelectedPromotionId] = useState(null); // State for selected promotion
  const [selectedPromotions, setSelectedPromotions] = useState(null); // State for selected promotion
  const [isLoading, setIsLoading] = useState(true);

  const amounts = ['100', '500', '1000', '2000']; // Predefined amounts

  // Fetch payment methods on component mount
  useEffect(() => {
    fetch('https://nofa365.com/api/payment-methods')
      .then(response => response.json())
      .then(data => setItems(data))
      .finally(setIsLoading(false))
      .catch(error => console.error('Error fetching payment methods:', error));
      
  }, []);

  // Fetch promotions on component mount
  useEffect(() => {
    fetch('https://nofa365.com/api/promotion')
      .then(response => response.json())
      .then(data => {
        const activePromotions = data.filter(promotion => promotion.status === 'active');
        setPromotions(activePromotions);
        // Set default to no promotion selected initially
        setSelectedPromotion(null);
        if (activePromotions.length > 0) {
          setSelectedPromotionId(activePromotions[0].id);
          setSelectedPromotion(activePromotions[0]); 
        }
      })
      .catch(error => console.error('Error fetching promotions:', error));
  }, []);


  const handleItemClick = (id) => {
    setActiveItem({ id });
    console.log(id)
  };

  

   

  const handleAmountClick = (amount) => setActiveAmount(amount);

  const handlePromotionChange = (event) => {
    const selectedId = event.target.value;
    setSelectedPromotions(selectedId);
    const promotion = promotions.find(promotion => promotion.id === parseInt(selectedId));
    setSelectedPromotion(promotion || null); 
  };
  if (isLoading) {
    return <div className="loading-container">
    <div className="loading-spinner"></div>
  </div>;
  }

  return (
    <div className="wallet on">
      <div className="fund-header">
        <div className="fund-action-bar">
          <Link to={'/account'}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </Link>
          <h6>Deposit</h6>
          <div className="">
            <FontAwesomeIcon icon={faMessage} />
            <p>Help</p>
          </div>
        </div>
        <div className="fund-nav">
          <ul className='px-3 py-3 mx-3'>
            <li className='nav-item active'>
              <Link className='nav-item' to={'/deposit'}>Deposit</Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-item' to={'/withdraw'}>Withdraw</Link>
            </li>
          </ul>
        </div>
      </div>

      <div className='fund-body active'>
        <div className='d-flex promotion-selecet align-items-center'>
          <p className='w-50'>
            <img className='px-2' src='https://img.b112j.com/bj/h5/assets/images/icon-set/icon-selectpromotion.svg?v=1727770742398' alt='' />
            Select Promotion
          </p>
          <select className='w-50 primary-bg text-white' name='promotion' onChange={handlePromotionChange} defaultValue="">
            
            {promotions.map(promotion => (
              <option key={promotion.id} value={promotion.id}>
                {promotion.name}
              </option>
            ))}
          </select>
        </div>

        <div className="card">
          <div className="card-title">
            <div className="card-title-l-line"></div>
            <h4>Payment Method</h4>
          </div>
          <div className="card-body">
            {items.map((item) => (
              item.status === 'active' && (
                <div
                  key={item.id}
                  className={`account-item ${activeItem.id === item.id ? 'active' : ''}`}
                  onClick={() => handleItemClick(item.id)}
                >
                  {/* Show bonus only if a promotion is selected */}
                  {selectedPromotion && (
                    <div className="tag-rebazte-money">
                      <p>
                        <span>+</span>{selectedPromotion.deposit_bouns}<span>%</span>
                      </p>
                    </div>
                  )}
                  <img
                    src={`https://nofa365.com/storage/${item.icon}`}
                    alt={item.name}
                    className={`item-icon ${item.name === 'Bank' ? 'bank' : ''}`}
                  />
                  <p className="text-light">{item.name}</p>
                  <div className='d-flex justify-content-end'>
                    <span className={`selecet-item ${activeItem.id === item.id ? 'active' : ''}`}></span>
                  </div>
                </div>
                
              )
            ))}
          </div>
        </div>

        <div className="card">
          <div className="card-title">
            <div className="card-title-l-line"></div>
            <h4>Deposit Channel</h4>
          </div>
          <div className="card-body">
            <div className="account-item active">
              <p className="text-light">Cash Out</p>
              <div className='d-flex justify-content-end'>
                    <span className={`selecet-item active`}></span>
                  </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-title">
            <div className="card-title-l-line"></div>
            <h4>Amount</h4>
          </div>
          <div className="card-body">
            {amounts.map((amount) => (
              <div
                key={amount}
                className={`account-item ${activeAmount === amount ? 'active' : ''}`}
                onClick={() => handleAmountClick(amount)}
              >
                <p className="text-light">{amount}</p>
                <div className='d-flex justify-content-end'>
                    <span className={`selecet-item ${activeAmount === amount ? 'active' : ''}`}></span>
                  </div>
              </div>
            ))}
          </div>
          <div className='amount-selected'>
            <span className='text-success'>৳</span>
            <p className='px-2'>{activeAmount}</p>
          </div>
        </div>

        <Link
          className="btn"
          to="/payment-request"
          state={{ paymentMethod: activeItem, amount: activeAmount?.replace(/,/g, ''), promotion_id: selectedPromotions ? selectedPromotions : null }}
        >
          Go to Payment Request
        </Link>
      </div>
    </div>
  );
}

export default Deposit;
