import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faMessage } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { Modal, Button, Form } from 'react-bootstrap';

function Profile() {
  const [userData, setUserData] = useState(null);
  const [showModal, setShowModal] = useState({ dofb: false, phone: false, email: false });
  const [dofb, setdofb] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('token');
      const remoteId = localStorage.getItem('remote_id');

      if (token && remoteId) {
        try {
          const response = await fetch(`https://nofa365.com/api/user/show?remote_id=${remoteId}`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });

          if (response.ok) {
            const data = await response.json();
            setUserData(data);
          } else {
            console.error('Failed to fetch user data:', response.status);
            const text = await response.text();
            console.error('Error page content:', text);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchUserData();
  }, []);

  const handleFieldClick = (field) => {
    if (!userData[field]) {
      // If the field value is null or empty, show the modal
      setShowModal((prev) => ({ ...prev, [field]: true }));
    } else {
      console.log(`${field} is already set:`, userData[field]);
    }
  };

  const handleFieldSubmit = async (field, value) => {
    const token = localStorage.getItem('token');
    try {
      const formattedValue = field === 'dofb' ? new Date(value).toISOString().split('T')[0] : value; // Format date to 'Y-m-d'
      const response = await fetch('https://nofa365.com/api/user/profile/update', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ [field]: formattedValue }),
      });
  
      if (response.ok) {
        // Update userData with the new value
        setUserData({ ...userData, [field]: formattedValue });
        setShowModal((prev) => ({ ...prev, [field]: false }));
      } else {
        const data = await response.json();
        setErrorMessage(data.message || `Error updating ${field}`);
      }
    } catch (error) {
      setErrorMessage(`An error occurred while updating your ${field}.`);
    }
  };

  return (
    <div className="funds profile personal-profile open">
      <div className="fund-header">
        <div className="fund-action-bar">
          <Link to={'/account'}><FontAwesomeIcon icon={faArrowLeft} /></Link>
          <h6>Personal Info</h6>
          <div className="">
            <FontAwesomeIcon icon={faMessage} />
            <p>Help</p>
          </div>
        </div>
      </div>

      {userData ? (
        <div className="fund-body">
          <img className="profile-bg" src="./bg/vip-card-bg-1.jpg" alt='' />
          <p className="text-success text-center">{userData.username}<span className="badge">Normal</span></p>

          <div className="profile-info">
            <div className="input-groups">
              <label>Full Name</label>
              <p className="text-warning py-3">{userData.name}</p>
            </div>
            <div className="input-groups">
              <label>Phone Number</label>
              <p className="text-success py-3" onClick={() => handleFieldClick('phone')}>
                {userData.phone || 'Click to add your Phone Number'}
              </p>
            </div>
            <div className="input-groups">
              <label>Email Address</label>
              <p className="text-success py-3" onClick={() => handleFieldClick('email')}>
                {userData.email || 'Click to add your Email'}
              </p>
            </div>
            <div className="input-groups">
              <label>Birthday</label>
              <p className="text-danger py-3" onClick={() => handleFieldClick('dofb')}>
                {userData.dofb || 'Click to add your Date of Birth'}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <p>Loading user data...</p>
      )}

      {/* Modal for updating Date of Birth */}
      <Modal show={showModal.dofb} onHide={() => setShowModal((prev) => ({ ...prev, dofb: false }))}>
        <Modal.Header closeButton>
          <Modal.Title>Update Date of Birth</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="dofb">
              <Form.Label>Date of Birth</Form.Label>
              <Form.Control
                className='text-white'
                autoComplete='off'
                type="date"
                name="dofb"
                value={dofb}
                onChange={(e) => {
                  setdofb(e.target.value);
                  console.log('Date of Birth Input:', e.target.value);
                }}
              />
            </Form.Group>
          </Form>
          {errorMessage && <p className="text-danger">{errorMessage}</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => handleFieldSubmit('dofb', dofb)}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for updating Phone */}
      <Modal show={showModal.phone} onHide={() => setShowModal((prev) => ({ ...prev, phone: false }))}>
        <Modal.Header closeButton>
          <Modal.Title>Update Phone Number</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="phone">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                className='text-white'
                autoComplete='off'
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Form.Group>
          </Form>
          {errorMessage && <p className="text-danger">{errorMessage}</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => handleFieldSubmit('phone', phone)}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for updating Email */}
      <Modal show={showModal.email} onHide={() => setShowModal((prev) => ({ ...prev, email: false }))}>
        <Modal.Header closeButton>
          <Modal.Title>Update Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="email">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                className='text-white'
                autoComplete='off'
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
          </Form>
          {errorMessage && <p className="text-danger">{errorMessage}</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => handleFieldSubmit('email', email)}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Profile;
