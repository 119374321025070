import React, { useEffect, useState } from "react";

function MoblileFavouriteSlide() {
    const [favouriteGames, setFavouriteGames] = useState([]); // State to hold favourite games data

    useEffect(() => {
        const fetchFavouriteGames = async () => {
            try {
                const response = await fetch('https://nofa365.com/api/sliders'); // Replace with actual API URL
                const data = await response.json();

                // Filter active games with slider_type "Favourite"
                const activeFavouriteGames = data.filter(game => game.status === 'active' && game.slider_type === 'Favourite');

                setFavouriteGames(activeFavouriteGames); // Set filtered games to state
            } catch (error) {
                console.error('Error fetching favourite games:', error);
            }
        };

        fetchFavouriteGames();
    }, []); // Empty dependency array to run only once on mount

    return (
        <div className="favourite-games content-title">
            <h2>Favourites</h2>
            <div className="favourite-games-items">
                <ul>
                    {favouriteGames.map((game, index) => (
                        <li className="fv-slider" key={index}>
                            <img src={`https://nofa365.com/storage/${game.thubmnail}`} alt={game.name} className="slide-image" /> {/* Adjust according to your API structure */}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default MoblileFavouriteSlide;
