import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";




const sections = [
    { title: "Home", icon: "./icon/icon-home.png", link: '/' },
    { title: "Sports", icon: "./icon/icon-sport.png", link: '/sports' },
    { title: "Casino", icon: "./icon/icon-casino.png", link: '/casino' },
    { title: "Slot", icon: "./icon/icon-slot.png", link: '/slot' },
    { title: "Table", icon: "./icon/icon-table.png", link: '/table' },
    { title: "Poker", icon: "./icon/icon-arcade.png", link: '/poker' },
    { title: "Crash", icon: "./icon/icon-crash.png", link: '/crash' },
    { title: "Fishing", icon: "./icon/icon-fish.png", link: '/fishing' },
    { title: "Lottery", icon: "./icon/icon-lottery.png", link: '/lottery' }
];


function TapBar(){
    const closeTabBar = () => {
        const chatElement = document.querySelector('.tap-bar');
            chatElement.classList.remove('open');
            document.body.classList.remove('no-scroll'); 
        
      };
      const [activeIndex, setActiveIndex] = useState(0);

    return(
        <div className="tap-bar">

            <div className="d-flex">
            
            <div className="mobile-nav">
                <ul>
                    <div className="sort-partner mx-2 py-2  my-3">
                        <img className="w-100 px-4 py-2" src="./logos/logo.png" alt=""/>
                        <h6 className="text-white sponsor-representation__title"> Front Of Shirt Partner </h6>
                        <img className="w-25" src="https://img.b112j.com/bj/h5/assets/images/sponsor/afc-bournemouth.png?v=1727170388190&source=mcdsrc" alt=""/>
                    </div>
                    {sections.map((section, index) => (
                        <li
                            key={section.title}
                            className={`nav-item ${activeIndex === index ? "active" : ""} home-nav`}
                            onClick={() => setActiveIndex(index)}
                        >
                            <Link to={section.link}>
                                <div>
                                <img src={section.icon} alt={section.title} />
                                <p>{section.title}</p>
                            </div>
                                </Link>
                        </li>
                    ))}
                    
                <div className="menu-spare my-2">
                <li className="nav-item home-nav"> 
                    <Link to={'/promotion'}>
                    <div>                          
                        <img src="./icon/icon-promotion.png" alt=""/> 
                          <p>Promotion</p>
                    </div>
                    </Link>
                  
                        </li>
                        <li className="nav-item home-nav"> 
                <a href="/">
                    <div>
                          
                          <img src="./icon/icon-referral.png" alt=""/> 
                          <p> Referral Program</p>
                          
                    </div>
                    </a>
                        </li>
                        <li className="nav-item home-nav"> 
                <a href="/">
                    <div>
                          
                          <img src="./icon/icon-vip.png" alt=""/> 
                          <p> VIP </p>
                          
                    </div>
                    </a>

                        </li>
                       
                </div>
            <div className="menu-spare my-2">
                <li className="nav-item home-nav advance-nav"> 
                    <a target="_blank" href="https://nofa365.com/login">
                        <div>
                            <img src="./icon/icon-affiliate.png" alt=""/> 
                            <p> Affiliate Program </p>
                            
                        </div>
                    </a>
                </li>
                <li className="nav-item home-nav advance-nav text-start"> 
                    <a href="/">
                        <div>
                            <img src="https://img.b112j.com/bj/h5/assets/images/icon-set/theme-icon/icon-talk.png?v=1727170388190" alt=""/> 
                            <p>  24/7 LiveChat 
                            <span className="nav-des">Provides 24/7 Quality service</span>
                                </p>
                        </div>
                    </a>
                </li>
                <li className="nav-item home-nav advance-nav text-start"> 
                    <a href="/">
                        <div>
                            <img src="https://img.b112j.com/bj/h5/assets/images/icon-set/menu/icon-ambassador.png?v=1727170388190" alt=""/> 
                            <p> Brand Ambassador
                            <span className="nav-des">Play with celebrity</span>
                                </p>
                        </div>
                    </a>
                </li>
                <div className="py-5"></div>
            </div>
        </ul>
        </div>
        <div onClick={closeTabBar} className="close-div">

        </div>
        </div>
        
    </div>
    );
}
export default TapBar;