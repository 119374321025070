import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal'; // Import Bootstrap Modal
import Button from 'react-bootstrap/Button';

function MyVip() {
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true); // Loading state
    const [vipCoin, setVipCoin] = useState(0); // State to hold the VIP coin input
    const [showModal, setShowModal] = useState(false); // Modal visibility state
    const [modalMessage, setModalMessage] = useState(''); // Modal message state
    const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));

    useEffect(() => {
        const fetchUserData = async () => {
            const token = localStorage.getItem('token');
            const remoteId = localStorage.getItem('remote_id');

            if (token && remoteId) {
                try {
                    const response = await fetch(`https://nofa365.com/api/user/show?remote_id=${remoteId}`, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    });

                    if (response.ok) {
                        const data = await response.json();
                        setUserData(data);
                    } else {
                        console.error('Failed to fetch user data:', response.status);
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                } finally {
                    setLoading(false); // Set loading to false after data fetching is complete
                }
            } else {
                setLoading(false); // If no token or remoteId, stop loading
            }
        };

        if (isLoggedIn) {
            fetchUserData();
            const interval = setInterval(fetchUserData, 1000);
            return () => clearInterval(interval);
        }
    }, [isLoggedIn]);

    const handleConvert = async () => {
        const remoteId = localStorage.getItem('remote_id');
        const token = localStorage.getItem('token');

        if (vipCoin < 10) {
            setModalMessage('Minimum VP Required: 10');
            setShowModal(true);
            return;
        }

        try {
            const response = await fetch(`https://nofa365.com/api/convert-vipcoin?remote_id=${remoteId}&vip_coin=${vipCoin}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            const data = await response.json();

            if (response.ok) {
                setModalMessage(data.success);
            } else {
                setModalMessage(data.message || 'An error occurred');
            }
        } catch (error) {
            console.error('Error converting VIP coins:', error);
            setModalMessage('An error occurred while converting coins');
        } finally {
            setShowModal(true); // Show modal with the message
        }
    };

    // Loading indicator
    if (loading) {
        return <div className="loading">Loading...</div>;
    }

    return (
        <div className="my-vip open">
            <div className="my-vip-header d-flex justify-content-between align-items-center">
                <Link to={'/account'}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                </Link>
                <h6>My VIP</h6>
                <div></div>
            </div>
            <div className="my-vip-body">
                <div className="vip-cards">
                    <div className="vip-card-body">
                        <div className="vip-lv-area">
                            <div className="d-flex align-items-center">
                                <div className="vip-icon">
                                    <img src="./icon/normal.png" alt="" />
                                </div>
                                <div className="vip-content">
                                    <h3>VIP LEVEL</h3>
                                    <h3>NORMAL</h3>
                                </div>
                            </div>
                            <div className="vip-icons">
                                <span className="item-icon"></span>
                            </div>
                        </div>
                    </div>
                    <div className="vip-bottom">
                        <a className="nav-link text-white">View VIP Details {' >>'}</a>
                    </div>
                </div>
                <div className="vip-content">
                    <div>
                        <p>Total VP</p>
                        <div className="d-flex vip-content-point align-items-center">
                            <p>{userData?.vip_coin}</p>
                            <span>VP</span>
                        </div>
                    </div>
                    <div className="vip-icons">
                        <span className="item-icon"></span>
                    </div>
                </div>
                <div className="content-title text-start mx-3">
                    <h4>Convert</h4>
                </div>
                <div className="vip-cards">
                    <div className="vip-card-body">
                        <div className="d-flex">
                            <div className="col-3">
                                <video
                                    width="100%"
                                    height="100%"
                                    autoPlay
                                    playsInline
                                    loop
                                    muted
                                    poster="https://img.m167cw.com/mcw/h5/assets/images/vip/coin-rotate-silver.png"
                                    style={{ width: '100%', height: 'auto' }}
                                >
                                    <source
                                        src="https://img.m167cw.com/mcw/h5/assets/images/vip/coin-rotate-silver-alpha.mov"
                                        type="video/quicktime"
                                    />
                                    <source
                                        src="https://img.m167cw.com/mcw/h5/assets/images/vip/coin-rotate-silver-alpha.webm"
                                        type="video/webm"
                                    />
                                </video>
                            </div>
                            <div className="col-9 vp">
                                <div className="d-flex justify-content-between">
                                    <p className="text-white text-start">VP</p>
                                    <p className="text-white text-end">Minimum VP Required: 10</p>
                                </div>
                                <input 
                                    placeholder="0" 
                                    name='vip_coin' 
                                    type="number" // Change input type to number
                                    value={vipCoin} 
                                    onChange={(e) => setVipCoin(e.target.value)} 
                                />
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="col-3">
                                <video
                                    width="100%"
                                    height="100%"
                                    autoPlay
                                    playsInline
                                    loop
                                    muted
                                    poster="https://img.b112j.com/bj/h5/assets/images/vip/bdt/coin-rotate-gold.png"
                                    style={{ width: '100%', height: 'auto' }}
                                >
                                    <source
                                        src="https://img.b112j.com/bj/h5/assets/images/vip/bdt/coin-rotate-gold-alpha.mov"
                                        type="video/quicktime"
                                    />
                                    <source
                                        src="https://img.b112j.com/bj/h5/assets/images/vip/bdt/coin-rotate-gold-alpha.webm"
                                        type="video/webm"
                                    />
                                </video>
                            </div>
                            <div className="col-9 vp">
                                <div className="d-flex justify-content-between">
                                    <p className="text-white text-start">Real Money</p>
                                </div>
                                <input placeholder="0"  value={vipCoin}  type="text" readOnly />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="my-vip-footer">
                <div onClick={handleConvert} className="vp-footer">
                    Convert to Real Money
                </div>
            </div>

            {/* Bootstrap Modal for displaying messages */}
            <Modal className='msg' show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Message</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalMessage}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default MyVip;
