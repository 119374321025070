import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTwitter, faPinterest, faYoutube, faTelegram } from '@fortawesome/free-brands-svg-icons';


function Footer(){
    return(
        <div className="footer">

            <div className="sponsorship">
                <p className="footer-title"> Sponsorships </p>
                <div className="row">
                    {/* being::item */}
                    <div className="col-lg-4 col-6 d-flex align-items-center sponsorship-item">
                        <div className="item-icon-spons">
                            <img src="https://img.b112j.com/bj/h5/assets/images/sponsor/afc-bournemouth.png" alt="" className="footer-spons-icon"/>
                        </div>
                        <div className="item-content-spons">
                            <h6 className="text-white">AFC Bournemouth</h6>
                            <span className="item-span">Front Of Shirt Partner</span>
                        </div>
                    </div>
                    {/* end::item */}
                    {/* being::item */}
                    <div className="col-lg-4 col-6 d-flex align-items-center sponsorship-item">
                        <div className="item-icon-spons">
                            <img src="https://img.b112j.com/bj/h5/assets/images/sponsor/sunrisers-eastern-cape.png?v=1725519198866&source=mcdsrc" alt="" className="footer-spons-icon"/>
                        </div>
                        <div className="item-content-spons">
                            <h6 className="text-white">Bologna FC 1909</h6>
                            <span className="item-span">Betting Partner</span>
                        </div>
                    </div>
                    {/* end::item */}
                    {/* being::item */}
                    <div className="col-lg-4 col-6 d-flex align-items-center sponsorship-item">
                        <div className="item-icon-spons">
                            <img src="https://img.b112j.com/bj/h5/assets/images/sponsor/deccan-gladiators.png" alt="" className="footer-spons-icon"/>
                        </div>
                        <div className="item-content-spons">
                            <h6 className="text-white">Quetta Gladiators</h6>
                            <span className="item-span">Titanium Sponsor</span>
                        </div>
                    </div>
                    {/* end::item */}
                    {/* being::item */}
                    <div className="col-lg-4 col-6 d-flex align-items-center sponsorship-item">
                        <div className="item-icon-spons">
                            <img src="https://img.b112j.com/bj/h5/assets/images/sponsor/bologna-fc-1909.png?v=1725519198866&source=mcdsrc" alt="" className="footer-spons-icon"/>
                        </div>
                        <div className="item-content-spons">
                            <h6 className="text-white">Deccan Gladiators </h6>
                            <span className="item-span">Title Sponsor</span>
                        </div>
                    </div>
                    {/* end::item */}
                    
                </div>
            </div>
            <div className="sponsorship">
                <p className="footer-title"> Brand Ambassadors </p>
                <div className="row">
                    {/* being::item */}
                    <div className="col-lg-4 col-6 d-flex align-items-center sponsorship-item">
                        <div className="item-icon-spons">
                            <img src="https://img.b112j.com/bj/h5/assets/images/footer/ambassador_signature/mia-k.png?v=1724901536196&source=mcdsrc" alt="" className="footer-spons-icon"/>
                        </div>
                        <div className="item-content-spons">
                            <h6 className="text-white"> 
                            Mia Khalifa</h6>
                            
                        </div>
                    </div>
                    {/* end::item */}
                    {/* being::item */}
                    <div className="col-lg-4 col-6 d-flex align-items-center sponsorship-item">
                        <div className="item-icon-spons">
                            <img src="https://img.b112j.com/bj/h5/assets/images/footer/ambassador_signature/kevin-pietersen.png?v=1724901536196&source=mcdsrc" alt="" className="footer-spons-icon"/>
                        </div>
                        <div className="item-content-spons">
                            <h6 className="text-white"> Kevin Pietersen</h6>
                            
                        </div>
                    </div>
                    {/* end::item */}
                    {/* being::item */}
                    <div className="col-lg-4 col-6 d-flex align-items-center sponsorship-item">
                        <div className="item-icon-spons">
                            <img src="https://img.b112j.com/bj/h5/assets/images/footer/ambassador_signature/amy-jacson.png?v=1724901536196&source=mcdsrc" alt="" className="footer-spons-icon"/>
                        </div>
                        <div className="item-content-spons">
                            <h6 className="text-white"> Amy Jackson</h6>
                            
                        </div>
                    </div>
                    {/* end::item */}
                    {/* being::item */}
                    <div className="col-lg-4 col-6 d-flex align-items-center sponsorship-item">
                        <div className="item-icon-spons">
                            <img src="https://img.b112j.com/bj/h5/assets/images/footer/ambassador_signature/hansika.png?v=1724901536196&source=mcdsrc" alt="" className="footer-spons-icon"/>
                        </div>
                        <div className="item-content-spons">
                            <h6 className="text-white"> Hansika Motwani</h6>
                            
                        </div>
                    </div>
                    {/* end::item */}
                    
                </div>
            </div>
            <div className="footer-logos">
               <div className="row">
                <div className="col-lg-3 col-6">
                    <div className="safe">
                    <h5 className="footer-logos-title text-start">Responsible Gaming</h5>
                    <img src="./icon/safe1.png" alt=""/>
                    <img src="./icon/safe2.png" alt=""/>
                    <img src="./icon/safe3.png" alt=""/>
                </div>
            </div>
            <div className="col-lg-3 col-6">
                    <div className="safe">
                    <h5 className="footer-logos-title text-start">Community Websites</h5>
                    <a className="nav-item brand-icon" href="/">
                     <FontAwesomeIcon icon={faFacebook}/>
                    </a>
                    <a className="nav-item brand-icon" href="/">
                     <FontAwesomeIcon icon={faInstagram}/>
                    </a>
                    <a className="nav-item brand-icon" href="/">
                     <FontAwesomeIcon icon={faTwitter}/>
                    </a>
                    <a className="nav-item brand-icon" href="/">
                     <FontAwesomeIcon icon={faPinterest}/>
                    </a>
                    <a className="nav-item brand-icon" href="/">
                     <FontAwesomeIcon icon={faYoutube}/>
                    </a>
                    <a className="nav-item brand-icon" href="/">
                     <FontAwesomeIcon icon={faTelegram}/>
                    </a>
                </div>
            </div>
            
            <div className="col-lg-9 col-12">
                <div className="payment-method">
                    <h5 className="footer-title">Payemnt Method</h5>
                        <img src="./icon/pay16.png" alt=""/>
                        <img src="./icon/pay22.png" alt=""/>
                        <img src="./icon/pay33.png" alt=""/>
                        <img src="./icon/pay34.png" alt=""/>
                        <img src="./icon/pay45.png" alt=""/>
                        <img src="./icon/pay47.png" alt=""/>
                        <img src="./icon/pay59.png" alt=""/>
                        <img src="./icon/pay91.png" alt=""/>
                </div>
                </div>
                <div className="col-6 gaming-linces">
                <h5 className="footer-title text-start "> Gaming License</h5>
                <img src="https://img.b112j.com/bj/h5/assets/images/footer/gaming_license.png?v=1724901536196&source=mcdsrc" alt=""/>
                </div>
                <div className="col-6 gaming-linces">
                <h5 className="footer-title text-start "> App Download</h5>
                <img src="https://img.b112j.com/bj/h5/assets/images/footer/android-en.png?v=1725519198866&source=mcdsrc" alt=""/>
                </div>
                <div className="col-12 footer-lince">
                    <h1 className="license-title ">Top Betting Exchange Sites Bangladesh, India & South East Asia</h1>
                    <p>A betting exchange is practically an online tool that is designed for gamblers to bet directly against each other and not involve any of the traditional bookmakers. Cricket Betting indicates two ways of betting in a cricket match. The first one is to bet on the overall outcome of a live cricket match. The other one is based on the live cricket score of a team in the first 6 overs.</p>

                </div>
                
               </div>
               <div className="row align-items-center">
                <div className="col-4">
                    <img src="/logos/logo.png" alt="" className="w-100"/>
                    </div> 
                    <div className="col-8">
                        <h6 className="footer-title">Win Like A King</h6>
                        <span className="item-span">© 2024 Baji Copyrights. All Rights Reserved</span>
                    </div>
                    </div>

            </div>


        </div>
    );

}
export default Footer;