import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faMessage, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

function Turnover() {
   const [activeTab, setActiveTab] = useState('active');
   const [turnovers, setTurnovers] = useState([]);
   const [transactionAmount, setTransactionAmount] = useState(0);
   const isLoggedIn = !!localStorage.getItem('token'); // Check if user is logged in

   const handleTabClick = (tab) => {
     setActiveTab(tab);
   };

   useEffect(() => {
      const fetchTurnovers = async () => {
         const token = localStorage.getItem('token');
         const remoteId = localStorage.getItem('remote_id');
         
         const response = await fetch(`https://nofa365.com/api/turnovers?remote_id=${remoteId}`, {
            method: 'GET',
            headers: {
               'Authorization': `Bearer ${token}`,
            },
         });

         const data = await response.json();
         if (data.turnover) {
            setTurnovers(data.turnover);
            setTransactionAmount(data.transactionamount);
         }
      };

      if (isLoggedIn) {
         fetchTurnovers();
         const interval = setInterval(fetchTurnovers, 1000); // Fetch every second
         return () => clearInterval(interval);
      }
   }, [isLoggedIn]);

   const formatDate = (dateString) => {
      const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
      const date = new Date(dateString);
      return date.toLocaleString('en-US', options);
   };

   return (
      <div className="funds betting-record turnover open">
         <div className="fund-header">
            <div className="fund-action-bar">
               <Link to={'/account'}>
                  <FontAwesomeIcon icon={faArrowLeft} />
               </Link>
               <h6>Turnover</h6>
               <div className="">
                  <FontAwesomeIcon icon={faMessage} />
                  <p>Help</p>
               </div>
            </div>
            <div className='fund-nav'>
               <ul className='p-3 mx-3'>
                  <li
                     className={`nav-item ${activeTab === 'active' ? 'active' : ''}`}
                     onClick={() => handleTabClick('active')}
                  >
                     Active
                  </li>
                  <li
                     className={`nav-item ${activeTab === 'complete' ? 'active' : ''}`}
                     onClick={() => handleTabClick('complete')}
                  >
                     Complete
                  </li>
               </ul>
            </div>
         </div>
         <div className={`fund-body turnover-body ${activeTab.toLowerCase()} ${activeTab === 'active' ? 'open' : ''}`}>
            {turnovers.filter(turnover => turnover.status === 'active').map(turnover => {
               const progress = turnover.required_turnover > 0 ? (transactionAmount / turnover.required_turnover) * 100 : 0;

               return (
                  <li className="ticket completed ng-star-inserted" key={turnover.id}>
                     <div className="ticket-inner">
                        <div className="ticket-inner-left">
                           <div className="title">{turnover.transaction?.promotion?.name || 'Deposit'}</div>
                           <div className="detail">
                              <div className="detail-btn">
                                 <a href='#'>Detail</a>
                              </div>
                           </div>
                           <div className="discount">
                              <div className="amount">
                                 <i id="locale-util-dicrective-142">৳ {turnover.required_turnover}</i>
                              </div>
                           </div>
                           <div className="progress-bar">
                              <div className="bar">
                                 <div className="bar-inner" style={{ width: `${progress}%` }}></div>
                              </div>
                              <div className="number">
                                 <span>৳{transactionAmount}</span>
                                 <span>{`${progress.toFixed(2)}%`}</span>
                              </div>
                           </div>
                        </div>
                        <div className="ticket-inner-right">
                           <div className="text ng-star-inserted" style={{ color: 'yellow' }}>
                              <span className="item-icon">
                                 <FontAwesomeIcon style={{ fontSize: '26px' }} className='text-warnning' icon={faSpinner} />
                              </span>
                              Pending
                           </div>
                        </div>
                     </div>
                     <div className="ticket-deco open-pop">
                        <div className="line"></div>
                        <div className="line"></div>
                     </div>
                  </li>
               );
            })}
         </div>
         <div className={`fund-body turnover-body ${activeTab.toLowerCase()} ${activeTab === 'complete' ? 'open' : ''}`}>
            {turnovers.filter(turnover => turnover.status === 'complete').map(turnover => {
               return (
                  <li className="ticket completed ng-star-inserted" key={turnover.id}>
                     <div className="ticket-inner">
                        <div className="ticket-inner-left">
                           <div className="title">{turnover.transaction?.promotion?.name || 'Deposit'}</div>
                           <div className="detail">
                              <div className="date ng-star-inserted">Event ends in: {formatDate(turnover.updated_at)}</div>
                              <div className="detail-btn">
                                 <a>Detail</a>
                              </div>
                           </div>
                           <div className="discount">
                              <div className="amount">
                                 <i id="locale-util-dicrective-142">৳{turnover.required_turnover}</i>
                              </div>
                           </div>
                           <div className="progress-bar">
                              <div className="bar">
                                 <div className="bar-inner" style={{ width: '100%' }}></div>
                              </div>
                              <div className="number">
                                 <span>৳{turnover.required_turnover}</span>
                                 <span>100%</span>
                              </div>
                           </div>
                        </div>
                        <div className="ticket-inner-right">
                           <div className="text ng-star-inserted">
                              <span className="item-icon" style={{ backgroundImage: 'url("https://img.m167cw.com/mcw/h5/assets/images/icon-set/icon-check-type01.svg")' }}></span>
                              Completed
                           </div>
                        </div>
                     </div>
                     <div className="ticket-deco open-pop">
                        <div className="line"></div>
                        <div className="line"></div>
                     </div>
                  </li>
               );
            })}
         </div>
      </div>
   );
}

export default Turnover;
