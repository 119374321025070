import React, { useState, useEffect } from 'react';
import LoginModal from '../modals/Login';
import SignupModal from '../modals/Signup';
import { Link } from 'react-router-dom';
function HeaderNav(){
    const OpenTabBar = () => {
        const chatElement = document.querySelector('.tap-bar');
            chatElement.classList.add('open');
            document.body.classList.add('no-scroll'); 
        
      };
      
    
    const logout = () => {
      localStorage.removeItem('token');
      localStorage.removeItem('sessionid');
      localStorage.removeItem('remote_id');
      localStorage.removeItem('username');
      window.location.href = '/';
    };
    
    
      const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));
      const [balance, setBalance] = useState(localStorage.getItem('balance') || 0);
    
      const fetchBalance = async () => {
        const token = localStorage.getItem('token');
        if (token) {
          try {
            const response = await fetch('https://nofa365.com/api/balance', {
              method: 'POST',
              headers: {
                'Authorization': `Bearer ${token}`,
              },
            });
    
            if (response.ok) {
              const data = await response.json();
              setBalance(data.balance);
              localStorage.setItem('balance', data.balance);
              setIsLoggedIn(token);
            } else {
              console.error('Failed to fetch balance with status:', response.status);
              const text = await response.text();
              console.error('Error page content:', text);
            }
          } catch (error) {
            console.error('Error fetching balance:', error);
          }
        }
      };
    
      useEffect(() => {
        if (isLoggedIn) {
          fetchBalance();
          const interval = setInterval(fetchBalance, 1000);
          return () => clearInterval(interval);
        }
      }, [isLoggedIn]);


    return(
        <div className="header-nav algin">
           <div className="logo-wrap d-flex align-items-center">
           <div className="header-logo d-flex align-items-center">
           
           <div _ngcontent-serverapp-c2173449144="" onClick={OpenTabBar} className="menu-btn d-flex">
            <ul _ngcontent-serverapp-c2173449144="">
              <li _ngcontent-serverapp-c2173449144=""></li>
                <li _ngcontent-serverapp-c2173449144=""></li>
                <li _ngcontent-serverapp-c2173449144=""></li></ul>
                </div>
            <Link to={'/'}><img alt=""  src="./logos/logo.png"/></Link>
            </div>
            <div className="spon-icon">
                <ul className="d-flex align-items-center my-0 mx-2 ">
                    <li><img className="spon-icon_u" alt="" src="./icon/deccan-gladiators.png"/></li>
                    <li><img className="spon-icon_u" alt=""  src="./icon/sunrisers-eastern-cape.png"/></li>
                    <li><img className="spon-icon_u" alt="" src="https://img.b112j.com/bj/h5/assets/images/sponsor/bologna-fc-1909.png"/></li>
                    <li><img className="spon-icon_u" alt=""  src="https://img.b112j.com/bj/h5/assets/images/sponsor/afc-bournemouth.png"/></li>
                </ul>
            </div>
            <div className="d-flex header-icon" >
            <Link>
                 <span
              className="item-icon"
              style={{
                maskImage: "url('https://img.b112j.com/bj/h5/assets/images/icon-set/index-theme-icon/header-appdownload-icon.svg')",
              }}>

            </span>
             <p>App</p>
          </Link>
          <Link>
                 <span
              className="item-icon"
              style={{
                maskImage: "url('https://img.b112j.com/bj/h5/assets/images/icon-set/index-theme-icon/header-appdownload-icon.svg')",
              }}>

            </span>
             <p>Help</p>
          </Link>
                 
                   
                 
                  
            </div>
           </div>

        <div className="header-menu">
            <ul>
            {isLoggedIn ? (
          <>
           <li className="nav-item"><a className="nav-link" href="/my-account">Main Wallet</a></li>
                <li className="nav-item"><a className="nav-link" href="/my-account">৳ {balance} BDT</a></li>
                <li className="nav-item"><a className="nav-link" href="/my-account"><span className="btn-ui">Deposit</span></a></li>
                <li className="nav-item"><a className="nav-link h-m-hover" href="/my-account">Member Care</a></li>
                <li className="nav-item"><a className="nav-link h-m-hover" onClick={logout} href="/">Log Out</a></li>
          </>
        ) : (
          <>
            <LoginModal/>
            <SignupModal/>
            
          </>
        )}
                
            </ul>
        </div>

        </div>
    );
}
export default HeaderNav;