import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap'; // Import Bootstrap modal components

function WithdrawRequest() {
  const location = useLocation();
  const navigate = useNavigate();
  
  const [paymentSystem, setPaymentSystem] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [accountNumber, setAccountNumber] = useState('');
  
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  
  const [showModal, setShowModal] = useState(false); // Manage modal visibility
  const [modalMessage, setModalMessage] = useState(''); // Message to show in modal
  
  const token = localStorage.getItem('token');
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('token');
      const remoteId = localStorage.getItem('remote_id');
      
      if (token && remoteId) {
        try {
          const response = await fetch(`https://nofa365.com/api/user/show?remote_id=${remoteId}`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });

          if (response.ok) {
            const data = await response.json();
            setUserData(data);
          } else {
            const text = await response.text();
            console.error('Error page content:', text);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchUserData();
  }, []);

  const { paymentMethod, amount } = location.state || {};

  useEffect(() => {
    if (!paymentMethod?.id || !amount) {
      navigate(-1);
    }
  }, [paymentMethod, amount, navigate]);

  useEffect(() => {
    if (paymentMethod?.id) {
      const fetchPaymentMethodDetails = async () => {
        try {
          const response = await fetch(`https://nofa365.com/api/showpayment-menthod?id=${paymentMethod.id}`, {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
          const data = await response.json();

          if (data && data.success) {
            setPaymentSystem(data.data);
          }
        } catch (error) {
          console.error('Error fetching payment method details:', error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchPaymentMethodDetails();
    }
  }, [paymentMethod, token]);

  const handleSubmit = async () => {
    setSubmitLoading(true);
    const remoteId = localStorage.getItem('remote_id');
  
    const requestBody = {
      remote_id: remoteId,
      account_number: accountNumber,
      amount: amount,
      payment_id: paymentSystem.id,
    };
  
    try {
      const response = await fetch('https://nofa365.com/api/submit-withdraw', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        if (response.status === 400) {
          setModalMessage(`Bad Request: ${errorData.error}`);
        } else {
          setModalMessage('Submission failed: ' + errorData.message);
        }
        setShowModal(true);
        return;
      }
  
      const data = await response.json();
  
      if (data.success) {
        setModalMessage('Submission successful!');
        setSubmissionSuccess(true);
      } else {
        setModalMessage(data.message);
      }
      setShowModal(true);
    } catch (error) {
      setModalMessage('An error occurred. Please try again.');
      setShowModal(true);
    } finally {
      setSubmitLoading(false);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="funds on">
      <div className="fund-header">
        <div className="fund-action-bar">
          <Link to={'/deposit'}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </Link>
          <h6>Payment Request</h6>
          <div></div>
        </div>
      </div>
      <div className="fund-body">
        <div className="card">
          <div className="input-group justify-content-around">
            <label className='text-light text-start'>Payment Method</label>
            <div className="password-wrapper">
              <img src={`https://nofa365.com/storage/${paymentSystem.icon}`} alt={paymentSystem.name} />
            </div>
            <label title='method-name' className='text-light text-start'>
              {paymentSystem?.name || ''}
            </label>
          </div>
         
          <div className="input-group justify-content-around">
            <label className='text-light'>Amount</label>
            <div className="password-wrapper">
              <input value={amount || ''} readOnly />
            </div>
          </div>
         
          <div className="input-group justify-content-around">
            <label className='text-light'> Your Account Number</label>
            <div className="password-wrapper">
              <input name='account_number' 
                     value={userData?.phone || ''} onChange={(e) => setAccountNumber(e.target.value)} readOnly />
            </div>
          </div>
          <button className="btn" onClick={handleSubmit} disabled={submitLoading}>
            {submitLoading ? 'Submitting...' : 'Submit'}
          </button>
        </div>
      </div>

      {/* Modal for error/success messages */}
      <Modal className='msg' show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
       
      </Modal>
    </div>
  );
}

export default WithdrawRequest;
