import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faMobile } from '@fortawesome/free-solid-svg-icons';

function MainBalance() {
    const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));
    const [balance, setBalance] = useState(localStorage.getItem('balance') || 0);

    const fetchBalance = async () => {
        const token = localStorage.getItem('token');
        

        if (token) {
            try {
                const response = await fetch('https://nofa365.com/api/balance', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json', // Specify content type
                    },
                });

                

                if (response.ok) {
                    const data = await response.json();
                    
                    setBalance(data.balance);
                    localStorage.setItem('balance', data.balance);
                } else {
                    

                    // Handle unauthorized status
                    if (response.status === 401) {
                        
                        handleLogout(); 
                    }
                }
            } catch (error) {
                console.error('Error fetching balance:', error);
            }
        } else {
            console.warn('No token found in local storage.');
        }
    };

    const handleLogout = () => {
        
        localStorage.removeItem('token');
        localStorage.removeItem('balance');
        localStorage.removeItem('sessionid');
        localStorage.removeItem('remote_id');
        localStorage.removeItem('username');
        setIsLoggedIn(false); // Automatically set login status to false
    };

    useEffect(() => {
        
        if (isLoggedIn) {
            fetchBalance(); // Fetch balance only if logged in
            const interval = setInterval(fetchBalance, 1000); // Fetch every second
            return () => clearInterval(interval); // Cleanup interval on unmount
        }
    }, [isLoggedIn]);

    return (
        <div className="main-balance">
            <div className="row justify-content-start">
                <div className="col-lg-6 col-5 main-balance-left">
                    <p>Main Wallet</p>
                    <span>৳ {balance}</span>
                </div>
                <div className="col-lg-6 col-7 main-balance-right text-start row align-items-center">
                    <div className="col-lg-8 col-6">
                        <p>Security Level</p>
                        <span className="text-danger">Unverified</span>
                    </div>
                    <div className="col-lg-4 col-6">
                        <FontAwesomeIcon icon={faEnvelope} className="unverified-icons" />
                        <FontAwesomeIcon icon={faMobile} style={{ padding: '8px 10px' }} className="unverified-icons" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MainBalance;
