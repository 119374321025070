import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faMessage, faFilter } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

function formatDate(dateString) {
  const date = new Date(dateString);
  const options = {
    day: 'numeric',
    month: 'short',
    hour: '2-digit',
    minute: '2-digit'
  };
  return date.toLocaleDateString('en-US', options);
}

// Utility to filter transactions by date range
function filterByDateRange(transactions, range) {
  const now = new Date();
  return transactions.filter(transaction => {
    const trxDate = new Date(transaction.created_at);
    switch (range) {
      case 'Today':
        return trxDate.toDateString() === now.toDateString();
      case 'Last Week':
        const lastWeek = new Date(now);
        lastWeek.setDate(now.getDate() - 7);
        return trxDate >= lastWeek && trxDate <= now;
      case 'Last Month':
        const lastMonth = new Date(now);
        lastMonth.setMonth(now.getMonth() - 1);
        return trxDate >= lastMonth && trxDate <= now;
      case 'This Year':
        return trxDate.getFullYear() === now.getFullYear();
      case 'All':
      default:
        return true;
    }
  });
}

function BettingRecord() {
  const [transactionData, setTransactionData] = useState([]);
  const [activeTab, setActiveTab] = useState('Settled');
  const [statusFilter, setStatusFilter] = useState('All');
  const [actionFilter, setActionFilter] = useState('All');
  const [dateFilter, setDateFilter] = useState('All');

  const handleOpenFilter = () => {
    const bodyElement = document.querySelector('.filltering'); 
    if (bodyElement) {
      bodyElement.classList.add('open');
      document.body.classList.add('no-scroll'); 
    }
  };

  const handleCloseFilter = () => {
    const bodyElement = document.querySelector('.filltering'); 
    if (bodyElement) {
      bodyElement.classList.remove('open');
      document.body.classList.remove('no-scroll'); 
    }
  };

  // Handle filter selection
  const handleStatusFilterChange = (status) => {
    setStatusFilter(status);
  };

  const handleActionFilterChange = (action) => {
    setActionFilter(action);
  };

  const handleDateFilterChange = (dateRange) => {
    setDateFilter(dateRange);
  };

  useEffect(() => {
    const fetchTransData = async () => {
      const token = localStorage.getItem('token');
      const remoteId = localStorage.getItem('remote_id');
      
      if (token && remoteId) {
        try {
          const response = await fetch(`https://nofa365.com/api/transaction/show?remote_id=${remoteId}`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });

          if (response.ok) {
            const data = await response.json();
            setTransactionData(data);
          } else {
            console.error('Failed to fetch user data with status:', response.status);
            const text = await response.text();
            console.error('Error page content:', text);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchTransData();
  }, []);

  // Tab click handler
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // Filter transactions based on the active tab
  const tabFilteredTransactions = transactionData.filter(transaction => {
    if (activeTab === 'Settled') {
      return transaction.action === "credit"; // Only settled transactions
    } else if (activeTab === 'Unsettled') {
      return transaction.action === "debit"; // Unsettled transactions
    }
    return true;
  });

  // Filter transactions based on the action filter
  const actionFilteredTransactions = tabFilteredTransactions.filter(transaction => 
    actionFilter === 'All' || transaction.action === actionFilter.toLowerCase()
  );

  // Final filtering based on status and date
  const filteredTransactions = filterByDateRange(
    actionFilteredTransactions.filter(transaction => 
      (statusFilter === 'All' || 
      (statusFilter === 'Success' && transaction.action === "credit") || 
      (statusFilter === 'Pending' && transaction.action === "debit") )
    ),
    dateFilter
  );
 

  return (
    <>
    <div className="funds betting-record bet-record open">
      <div className="fund-header">
        <div className="fund-action-bar">
        
        <Link to={'/account'}><FontAwesomeIcon  icon={faArrowLeft} /></Link>
         
          <h6>Betting Record</h6>
          <div>
            <FontAwesomeIcon icon={faMessage} />
            <p>Help</p>
          </div>
        </div>
        <div className='fund-nav'>
          <ul className='p-3 mx-3'>
            <li
              className={`nav-item ${activeTab === 'Settled' ? 'active' : ''}`}
              onClick={() => handleTabClick('Settled')}
            >
              Win
            </li>
            <li
              className={`nav-item ${activeTab === 'Unsettled' ? 'active' : ''}`}
              onClick={() => handleTabClick('Unsettled')}
            >
              Loss
            </li>
          </ul>
        </div>
      </div>

      {/* Transactions Table */}
      <div className={`fund-body ${activeTab.toLowerCase()} ${activeTab === 'Settled' ? 'open' : ''}`}>
        <div className='d-flex justify-content-between align-items-center mx-3'>
          <div className='filter-heading'>{dateFilter}</div>
          <FontAwesomeIcon onClick={handleOpenFilter} icon={faFilter} className='filter-icon' />
        </div>
        <table>
          <thead>
            <tr>
              <th>Type</th>
              <th>Amount</th>
              <th>Status</th>
              <th>TRX Date</th>
            </tr>
          </thead>
          <tbody>
            {filteredTransactions.map((transaction, index) => (
              <tr key={index}>
                <th scope="row" className='text-capitalize'>{transaction.action}</th>
                <td>{transaction.amount}</td>
                <td>
               
                {transaction.action === "credit" && <span className="text-success-alert">Win</span>}
                {transaction.action === "debit" && <span className="text-pending-alert">Loss</span>}
                </td>
                <td>{formatDate(transaction.created_at)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

     
    </div>
     {/* Filter Sidebar */}
     <div className="filltering">
        <div className="filter-header d-flex justify-content-between align-items-center text-white">
          <FontAwesomeIcon onClick={handleCloseFilter} icon={faArrowLeft} />
          <h6>Betting Record Filter</h6>
          <div></div>
        </div>
        <div className="filter-body">
          
         
          
          <span className="filter-title">Date</span>
          <div className="row">
            <div 
              className={`filter-item ${dateFilter === 'Today' ? 'active' : ''}`} 
              onClick={() => handleDateFilterChange('Today')}
            >
              Today
            </div>
            <div 
              className={`filter-item ${dateFilter === 'Last Week' ? 'active' : ''}`} 
              onClick={() => handleDateFilterChange('Last Week')}
            >
              Last Week
            </div>
            <div 
              className={`filter-item ${dateFilter === 'Last Month' ? 'active' : ''}`} 
              onClick={() => handleDateFilterChange('Last Month')}
            >
              Last Month
            </div>
            <div 
              className={`filter-item ${dateFilter === 'This Year' ? 'active' : ''}`} 
              onClick={() => handleDateFilterChange('This Year')}
            >
              This Year
            </div>
            <div 
              className={`filter-item ${dateFilter === 'All' ? 'active' : ''}`} 
              onClick={() => handleDateFilterChange('All')}
            >
              All
            </div>
          </div>
        </div>
        <div className="filter-footer">
          <button onClick={handleCloseFilter} className="btn-ui">
          Confirm
          </button>
        </div>
      </div>
    </>
  );
}

export default BettingRecord;
