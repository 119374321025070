import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';  // Import Bootstrap modal components

function PaymentRequest() {
  const location = useLocation();
  const navigate = useNavigate();
  
  const [paymentSystem, setPaymentSystem] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [accountNumber, setAccountNumber] = useState('');
  const [transactionId, setTransactionId] = useState('');
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [modalMessage, setModalMessage] = useState('');  // For displaying message in modal
  const [showModal, setShowModal] = useState(false);  // For toggling modal visibility
  
  const token = localStorage.getItem('token');

  // Destructure the state from location
  const { paymentMethod, amount, promotion_id } = location.state || {};

  // Redirect back if any of the required data is missing
  useEffect(() => {
    if (!paymentMethod?.id || !amount) {
      navigate(-1);
      console.log('Redirecting due to missing paymentMethod or amount');
    }
  }, [paymentMethod, amount, navigate]);

  // Fetch payment method details using the paymentMethod.id
  useEffect(() => {
    if (paymentMethod?.id) {
      const fetchPaymentMethodDetails = async () => {
        try {
          const response = await fetch(`https://nofa365.com/api/showpayment-menthod?id=${paymentMethod.id}`, {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
          const data = await response.json();

          if (data && data.success) {
            setPaymentSystem(data.data);
          }
        } catch (error) {
          setModalMessage('Error fetching payment method details.');
          setShowModal(true); // Show modal on error
        } finally {
          setIsLoading(false);
        }
      };

      fetchPaymentMethodDetails();
    }
  }, [paymentMethod, token]);

  const handleCopy = () => {
    navigator.clipboard.writeText(paymentMethod?.name || '')
      .then(() => {
        setModalMessage('Copied to clipboard!');
        setShowModal(true);  // Show modal on success
      })
      .catch(err => {
        setModalMessage('Failed to copy text.');
        setShowModal(true);  // Show modal on error
      });
  };

  const handleSubmit = async () => {
    setSubmitLoading(true);
    const remoteId = localStorage.getItem('remote_id');
  
    const requestBody = {
      remote_id: remoteId,
      account_number: accountNumber,
      transaction_id: transactionId,
      amount: amount,
      payment_id: paymentSystem.id,
      promotion_id: promotion_id,
    };
  
    try {
      const response = await fetch('https://nofa365.com/api/submit-deposit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        setModalMessage('Submission failed: ' + errorData.message);
        setShowModal(true); // Show modal on error
        return;
      }
  
      const data = await response.json();
      
      if (data.success) {
        setSubmissionSuccess(true);
        setModalMessage('Submission successful!');
      } else {
        setModalMessage(data.message);
      }
      setShowModal(true);  // Show modal with success or error message
    } catch (error) {
      setModalMessage('An error occurred. Please try again.');
      setShowModal(true);  // Show modal on error
    } finally {
      setSubmitLoading(false);
    }
  };

  // Close the modal
  const handleCloseModal = () => {
    setShowModal(false);
  };

  if (isLoading) {
    return <div className="loading-container">
    <div className="loading-spinner"></div>
  </div>;
  }

  return (
    <div className="funds on">
      <div className="fund-header">
        <div className="fund-action-bar">
          <Link to={'/deposit'}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </Link>
          <h6>Payment Request</h6>
          <div></div>
        </div>
      </div>
      <div className="fund-body">
        <div className="card">
          <div className="input-group justify-content-between">
            <label className='text-light text-start'>Payment Method</label>
            <div className="password-wrapper">
              <img src={`https://nofa365.com/storage/${paymentSystem.icon}`} alt={paymentSystem.name} />
            </div>
            <label title='method-name' className='text-light text-start'>
              {paymentSystem?.name || ''}
            </label>
          </div>
          <div className="input-group justify-content-between">
            <label className='text-light text-start'>Account Number</label>
            <div className="password-wrapper">
              <input value={paymentSystem.account_number || ''} readOnly />
            </div>
            <FontAwesomeIcon className='text-white' onClick={handleCopy} icon={faCopy} />
          </div>
          <div className="input-group justify-content-start">
            <label className='text-light'>Amount</label>
            <div className="password-wrapper">
              <input value={amount || ''} readOnly />
            </div>
          </div>
          <div className="input-group justify-content-start">
            <label className='text-light'>TRX ID</label>
            <div className="password-wrapper">
              <input name='transaction_id' placeholder='Enter Your Transaction ID' 
                     value={transactionId} onChange={(e) => setTransactionId(e.target.value)} />
            </div>
          </div>
          <div className="input-group justify-content-start">
            <label className='text-light'>Sender Account Number</label>
            <div className="password-wrapper">
              <input name='account_number' placeholder='Enter Your Sender Account Number' 
                     value={accountNumber} onChange={(e) => setAccountNumber(e.target.value)} />
            </div>
          </div>
          <button className="btn" onClick={handleSubmit} disabled={submitLoading}>
            {submitLoading ? 'Submitting...' : 'Submit'}
          </button>
          {submissionSuccess && <p className="text-success">Submission successful!</p>}
        </div>
      </div>

      {/* Modal for displaying messages */}
      <Modal className='msg' show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalMessage}
        </Modal.Body>
        
      </Modal>
    </div>
  );
}

export default PaymentRequest;
