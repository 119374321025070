import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

function Register() {
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        confirmPassword: '',
        name: '',
        phone: '',
        email: '',
        status: 'active',
        currency: 'BDT'
    });

    const [errors, setErrors] = useState({});
    const [passwordShown, setPasswordShown] = useState(false);
    const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordShown(!passwordShown);
    };

    const toggleConfirmPasswordVisibility = () => {
        setConfirmPasswordShown(!confirmPasswordShown);
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    // Validation logic
    const validate = () => {
        let tempErrors = {};
        const usernamePattern = /^[a-zA-Z0-9]+$/;
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phonePattern = /^[0-9]{10,15}$/; // Modify the regex based on your phone format

        // Username validation
        if (!formData.username) tempErrors.username = "Username is required";
        else if (!usernamePattern.test(formData.username)) tempErrors.username = "Invalid username (only letters and numbers)";

        // Email validation
        if (!formData.email) tempErrors.email = "Email is required";
        else if (!emailPattern.test(formData.email)) tempErrors.email = "Invalid email address";

        // Phone validation
        if (!formData.phone) tempErrors.phone = "Phone number is required";
        else if (!phonePattern.test(formData.phone)) tempErrors.phone = "Invalid phone number (10-15 digits)";

        // Password validation
        if (!formData.password) tempErrors.password = "Password is required";
        else if (formData.password.length < 8) tempErrors.password = "Password must be at least 8 characters";

        // Confirm Password validation
        if (!formData.confirmPassword) tempErrors.confirmPassword = "Confirm Password is required";
        else if (formData.confirmPassword !== formData.password) tempErrors.confirmPassword = "Passwords do not match";

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            try {
                const response = await fetch('https://nofa365.com/api/v1/create-players', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                });
                const data = await response.json();

                if (response.ok) {
                    console.log("Signup successful:", data);
                    // Handle successful registration (e.g., redirect, show success message)
                } else {
                    console.log("Signup failed:", formData);
                    // Handle signup failure (e.g., show error messages from API response)
                }
            } catch (error) {
                console.error("Error during signup:", error);
                // Handle network errors
            }
        } else {
            console.log("Form has errors. Please fix them and try again.");
        }
    };

    return (
        <div className="register">
            <div className="register-header text-white">
                <a className="nav-link" href="/"><FontAwesomeIcon icon={faAngleLeft} className="px-3" /></a>
                <h6>SIGN UP</h6>
                <span></span>
            </div>
            <div className="register-body">
                <div className="signup-banner">
                    <img src="./bg/en-sponsor.jpg" className="w-100" alt="" />
                </div>

                <div className="p-3">
                    <form onSubmit={handleSubmit}>
                        <div className="card">
                            <div className="bottom-input-group">
                                <label>Username</label>
                                <div className="input-field-group">
                                    <input
                                        name="username"
                                        type="text"
                                        placeholder="Username"
                                        value={formData.username}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            {errors.username && <span className="validation-error text-danger">{errors.username}</span>}

                            <div className="bottom-input-group">
                                <label>Password</label>
                                <div className="input-field-group">
                                    <input
                                        name="password"
                                        type={passwordShown ? "text" : "password"}
                                        placeholder="Password"
                                        value={formData.password}
                                        onChange={handleChange}
                                    />
                                    <FontAwesomeIcon
                                        icon={passwordShown ? faEyeSlash : faEye}
                                        onClick={togglePasswordVisibility}
                                        className="text-white password-toggle-icon"
                                    />
                                </div>
                            </div>
                            {errors.password && <span className="validation-error text-danger">{errors.password}</span>}

                            <div className="bottom-input-group">
                                <label>Confirm Password</label>
                                <div className="input-field-group">
                                    <input
                                        name="confirmPassword"
                                        type={confirmPasswordShown ? "text" : "password"}
                                        placeholder="Confirm Password"
                                        value={formData.confirmPassword}
                                        onChange={handleChange}
                                    />
                                    <FontAwesomeIcon
                                        icon={confirmPasswordShown ? faEyeSlash : faEye}
                                        onClick={toggleConfirmPasswordVisibility}
                                        className="text-white password-toggle-icon"
                                    />
                                </div>
                            </div>
                            {errors.confirmPassword && <span className="validation-error text-danger">{errors.confirmPassword}</span>}

                            <div className="bottom-input-group">
                                <label>Currency</label>
                                <div className="input-field-group">
                                    <select
                                        name="currency"
                                        value={formData.currency}
                                        onChange={handleChange}
                                    >
                                        <option>BDT</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="bottom-input-group">
                                <label>Full Name</label>
                                <div className="input-field-group">
                                    <input
                                        name="name"
                                        type="text"
                                        placeholder="Full Name"
                                        value={formData.name}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <div className="bottom-input-group">
                                <label>Phone Number</label>
                                <div className="input-field-group">
                                    <input
                                        name="phone"
                                        type="text"
                                        placeholder="Phone Number"
                                        value={formData.phone}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            {errors.phone && <span className="validation-error text-danger">{errors.phone}</span>}

                            <div className="bottom-input-group">
                                <label>Email Address</label>
                                <div className="input-field-group">
                                    <input
                                        name="email"
                                        type="text"
                                        placeholder="Email Address"
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            {errors.email && <span className="validation-error text-danger">{errors.email}</span>}
                        </div>

                        <button type="submit" className="btn">Register</button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Register;
