import React, { useState, useEffect } from "react";
import { useSwipeable } from "react-swipeable";
import { useNavigate } from 'react-router-dom';

const sections = [
    { title: "Sports", icon: "./icon/icon-sport.png" },
    { title: "Casino", icon: "./icon/icon-casino.png", type: "LIVE" },
    { title: "Slot", icon: "./icon/icon-slot.png", type: "SLOT" },
    { title: "Table", icon: "./icon/icon-table.png", type: "TABLE" },
    { title: "Poker", icon: "./icon/icon-arcade.png", type: "BINGO" },
    { title: "Crash", icon: "./icon/icon-crash.png", type: "EGAME" },
    { title: "Fishing", icon: "./icon/icon-fish.png", type: "FH" },
    { title: "Lottry", icon: "./icon/icon-lottery.png", type: "LOTTO" },
    
];

function MobileNav() {
    const [activeIndex, setActiveIndex] = useState(0);
    const [systems, setSystems] = useState([]);
    const [providers, setProviders] = useState([]);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleSwipe = (direction) => {
        if (direction === "left" && activeIndex < sections.length - 1) {
            setActiveIndex(activeIndex + 1);
        } else if (direction === "right" && activeIndex > 0) {
            setActiveIndex(activeIndex - 1);
        }
    };
    const handleCasinoClick = (system, type, title) => {
        navigate('/casino', { state: { system, type, title } });
    };
    

    const handleGameClick = async (gameId) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                setError('User not logged in');
                return;
            }

            const response = await fetch('https://nofa365.com/api/get-game-direct', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    game_id: gameId,
                    lang: 'en',
                    play_for_fun: false,
                    home_url: 'https://worldbaji.live',
                }),
            });

            const data = await response.json();

            if (response.ok) {
                navigate('/sports', { state: { soprtsLink: data.response.response.embed_code } });
                console.log( data.response.response.embed_code)
            } else {
                setError(data.message || 'An unexpected error occurred');
            }
        } catch (error) {
            console.error('Error fetching game details:', error);
            setError('An unexpected error occurred');
        }
    };

    const fetchSystems = async (type) => {
        try {
            const response = await fetch(`https://nofa365.com/api/get-system?type=${type}`);
            const data = await response.json();
            if (response.ok) {
                setSystems(data.systems || []);
                setProviders([]); // Clear providers when systems change
                // Call fetchProviders with the new systems
                fetchProviders(data.systems);
            } else {
                setError(data.message || 'Failed to fetch systems');
            }
        } catch (error) {
            console.error('Error fetching systems:', error);
            setError('An unexpected error occurred while fetching systems');
        }
    };
    

    const fetchProviders = async (systems) => {
        try {
            const response = await fetch(`https://nofa365.com/api/get-provider?system=${JSON.stringify(systems)}`);
            const data = await response.json();
            if (response.ok) {
                console.log(data);
                setProviders(data.data || []);
            } else {
                setError(data.message || 'Failed to fetch providers');
            }
        } catch (error) {
            console.error('Error fetching providers:', error);
            setError('An unexpected error occurred while fetching providers');
        }
    };
    

    const handleSectionClick = (index) => {
        setActiveIndex(index);
        const selectedSection = sections[index];
        if (selectedSection.type) {
            fetchSystems(selectedSection.type);
        } else {
            setSystems([]);
            setProviders([]);
        }
    };

    useEffect(() => {
        if (activeIndex === 0) {
            setSystems([]);
            setProviders([]);
        }
    }, [activeIndex]);

    const handlers = useSwipeable({
        onSwipedLeft: () => handleSwipe("left"),
        onSwipedRight: () => handleSwipe("right"),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
    });

    return (
        <>
            <div className="mobile-nav">
                <ul>
                    {sections.map((section, index) => (
                        <li
                            key={section.title}
                            className={`nav-item ${activeIndex === index ? "active" : ""}`}
                            onClick={() => handleSectionClick(index)}>
                            <div>
                                <img src={section.icon} alt={section.title} />
                                <p>{section.title}</p>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>

            <div className="menu-overview content-title">
                <h2>{sections[activeIndex].title}</h2>
                <div className="menu-overview-list active" {...handlers}>
                    {activeIndex === 0 && (
                       <>
                           <div className="menu-overview-list-item">
                            <a onClick={() => handleGameClick(65363)}>
                                <img src="./icon/icon-exchange.svg" className="sports-icon-menu" alt="Exchange" />
                                <p>Exchange</p>
                                </a>
                            </div>
                            <div className="menu-overview-list-item">
                            <a >
                                <img src="./icon/icon-sportbook.svg" className="sports-icon-menu" alt="iSports" />
                                <p>iSports</p>
                                </a>
                            </div>
                            <div className="menu-overview-list-item">
                            <a>
                                <img src="./icon/icon-sbov2.svg" className="sports-icon-menu" alt="SBO Sports" />
                                <p>SBO Sports</p>
                                </a>
                            </div>
                            <div className="menu-overview-list-item">
                            <a>
                                <img src="./icon/icon-awcme1sport.svg" className="sports-icon-menu" alt="E1SPORTS" />
                                <p>E1SPORTS</p>
                                </a>
                            </div>
                            <div className="menu-overview-list-item">
                            <a>
                                <img src="./icon/icon-horsebook.svg" className="sports-icon-menu" alt="Hoursbook" />
                                <p>Hoursbook</p>
                                </a>
                            </div>
                            <div className="menu-overview-list-item">
                            <a>
                                <img src="./icon/icon-virtual.svg" className="sports-icon-menu" alt="Virtual" />
                                <p>Virtual</p>
                                </a>
                            </div>
                            <div className="menu-overview-list-item">
                                <img src="./icon/icon-kabaddi.svg" className="sports-icon-menu" alt="Kabaddu" />
                                <p>Kabaddu</p>
                            </div>
                            <div className="menu-overview-list-item">
                                <img src="./icon/icon-powerelevenv2.svg" className="sports-icon-menu" alt="FANSASY" />
                                <p>FANSASY</p>
                            </div>
                            </>
                    )}
                    {activeIndex > 0 && 
                        providers.length > 0 && (
                       
                         <>
                            {providers.map((provider, index) => (
                                
                                <div className="menu-overview-list-item" key={index}>
                                    <a onClick={() => handleCasinoClick(provider.platform, sections[activeIndex].type, sections[activeIndex].title )}>
                                    <img src={`./icon/platform/${provider.platform}.png`} alt={provider.platform} />
                                        <p>{provider.platform}</p>
                                    </a>
                                </div>
                               
                            ))}
                        </>
                        
                    )}
                    {error && <p className="error">{error}</p>}
                </div>
                </div>
        </>
    );
}

export default MobileNav;