import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap styles are included
import { Modal } from 'react-bootstrap'; // Import Bootstrap modal

function PromotionGrid() {
    const [promotions, setPromotions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedPromotion, setSelectedPromotion] = useState(null); // State for selected promotion
    const [showModal, setShowModal] = useState(false); // State for modal visibility
    const [loadingDetails, setLoadingDetails] = useState(false); // State for loading details

    useEffect(() => {
        const fetchPromotions = async () => {
            try {
                const response = await fetch("https://nofa365.com/api/promotion");
                if (!response.ok) {
                    throw new Error("Failed to fetch promotions");
                }
                const data = await response.json();
                // Filter promotions based on status
                const filteredPromotions = data.filter(promotion => promotion.status === 'display');
                setPromotions(filteredPromotions);
            } catch (error) {
                console.error("Error fetching promotions:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchPromotions();
    }, []);

    // Function to handle modal open
    const handleShowModal = async (promotionId) => {
        setLoadingDetails(true); // Start loading details
        try {
            const response = await fetch(`https://nofa365.com/api/promotions/${promotionId}`);
            if (!response.ok) {
                throw new Error("Failed to fetch promotion details");
            }
            const promotionDetails = await response.json();
            setSelectedPromotion(promotionDetails); // Set the selected promotion
        } catch (error) {
            console.error("Error fetching promotion details:", error);
        } finally {
            setLoadingDetails(false); // Stop loading details
            setShowModal(true); // Show the modal
        }
    };

    // Function to handle modal close
    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedPromotion(null); // Reset selected promotion
    };

    // Loading indicator
    if (loading) {
        return <div className="loading">Loading...</div>;
    }

    return (
        <div className="container my-2 promotion">
            <div className="row">
                {promotions.map((promotion, index) => (
                    <div className="col-lg-6 col-12" key={index}>
                        <div className="promotion-card">
                            <img src={`https://nofa365.com/storage/${promotion.thumbnail}`} alt={promotion.name} />
                            <div className="line"></div>
                            <div className="promotion-body">
                                <div className="promotion-title">
                                    <h1 className="text-white">{promotion.name}</h1>
                                    <span className="text-end">{`Long Time`}</span>
                                </div>
                                <p>{promotion.desc ? promotion.desc.split(' ').slice(0, 5).join(' ') : 'No description available'}</p>
                                <p>
                                    <FontAwesomeIcon icon={faClock} />
                                    {` Start: ${new Date(promotion.start_date).toLocaleDateString('en-GB')} ~ End: ${new Date(promotion.end_date).toLocaleDateString('en-GB')}`}
                                </p>
                                <div className="my-2"></div>
                                <button className="btn btn-primary px-4 py-2" onClick={() => handleShowModal(promotion.id)}>Details</button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {/* Bootstrap Modal for Promotion Details */}
            <Modal className="msg modal-promotion" show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedPromotion ? selectedPromotion.name : ''}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loadingDetails ? (
                        <p>Loading details...</p> // Loading state
                    ) : (
                        selectedPromotion && (
                            <>
                                <img src={`https://nofa365.com/storage/${selectedPromotion.thumbnail}`} alt={selectedPromotion.name} className="img-fluid mb-3" />
                                <h5>Description:</h5>
                                <p>{selectedPromotion.desc}</p>
                                <p><strong>Start:</strong> {new Date(selectedPromotion.start_date).toLocaleDateString('en-GB')}</p>
                                <p><strong>End:</strong> {new Date(selectedPromotion.end_date).toLocaleDateString('en-GB')}</p>
                            </>
                        )
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleCloseModal}>Close</button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default PromotionGrid;
