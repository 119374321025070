import React, { useEffect, useState } from "react";

function FuturesGames() {
    const [games, setGames] = useState([]); // State to hold game data

    useEffect(() => {
        const fetchGames = async () => {
            try {
                const response = await fetch('https://nofa365.com/api/sliders'); // Replace with the actual API URL for games
                const data = await response.json();

                // Filter active games with slider_type "Features"
                const activeFeaturedGames = data.filter(game => game.status === 'active' && game.slider_type === 'Features');

                setGames(activeFeaturedGames); // Set the filtered games to state
            } catch (error) {
                console.error('Error fetching games:', error);
            }
        };

        fetchGames();
    }, []); // Empty dependency array to run only once on mount

    return (
        <div className="favourite-games content-title my-1">
            <h2>Featured Games</h2>
            <div className="favourite-games-items featured-game">
                <ul>
                    {games.map((game, index) => (
                        <li className="fe-slider" key={index}>
                            <img  src={`https://nofa365.com/storage/${game.thubmnail}`} alt={game.name} className="slide-image" /> {/* Assuming the API returns image URL in "image_url" and name in "name" */}
                            <p className="text-white">{game.name}</p>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default FuturesGames;
