import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faMessage, faInfo } from '@fortawesome/free-solid-svg-icons';
import MainBalance from '../common/MainBalance';
import { Link } from 'react-router-dom';

function Funds() {
  const [activeItem, setActiveItem] = useState('');  // Initial default payment method
  const [activeAmount, setActiveAmount] = useState(null);
  const [activeTab, setActiveTab] = useState('Deposit');
  const [items, setItems] = useState([]);

  const amounts = ['100', '500', '1,000', '2,000'];

  // Fetch payment methods on component mount
  useEffect(() => {
    fetch('https://nofa365.com/api/payment-methods')
      .then(response => response.json())
      .then(data => {
        setItems(data);
      })
      .catch(error => {
        console.error('Error fetching payment methods:', error);
      });
  }, []);

  const handleItemClick = (id) => {
    setActiveItem({id });
  };

  const handleAmountClick = (amount) => {
    const numericAmount = parseInt(amount.replace(/,/g, ''), 10);
    setActiveAmount(prevAmount => {
      if (prevAmount !== null) {
        const numericPrevAmount = parseInt(prevAmount.replace(/,/g, ''), 10);
        return (numericPrevAmount + numericAmount).toLocaleString();
      } else {
        return numericAmount.toLocaleString();
      }
    });
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="funds wallet open">
      <div className="fund-header">
        <div className="fund-action-bar">
          <Link to={'/'}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </Link>
          <h6>Funds</h6>
          <div className="">
            <FontAwesomeIcon icon={faMessage} />
            <p>Help</p>
          </div>
        </div>
        <div className="fund-nav">
          <ul className='px-3 py-3 mx-3'>
            <li
              className={`nav-item ${activeTab === 'Deposit' ? 'active' : ''}`}
              onClick={() => handleTabClick('Deposit')}
            >
              Deposit
            </li>
            <li
              className={`nav-item ${activeTab === 'Withdraw' ? 'active' : ''}`}
              onClick={() => handleTabClick('Withdraw')}
            >
              Withdraw
            </li>
          </ul>
        </div>
      </div>

      {/* Deposit Tab */}
      <div className={`fund-body ${activeTab.toLowerCase()} ${activeTab === 'Deposit' ? 'open' : ''}`}>
        <div className='d-flex'>
          <select name='promotion'>
            <option>Regular Deposit 100%</option>
            <option>Regular Deposit 100%</option>
            <option>Regular Deposit 100%</option>
          </select>
        </div>

        <div className="card">
          <div className="card-title">
            <div className="card-title-l-line"></div>
            <h4>Payment Method</h4>
          </div>
          <div className="card-body">
            {items.map((item) => (
              // Render only active payment methods
              item.status === 'active' && (
                <div
                  key={item.id}
                  className={`account-item ${activeItem.id === item.id ? 'active' : ''}`}
                  onClick={() => handleItemClick(item.id)}
                >
                  <img
                    src={`https://nofa365.com/${item.icon}`}
                    alt={item.name}
                    className={`item-icon ${item.name === 'Bank' ? 'bank' : ''}`}
                  />
                  <p className="text-light">{item.name}</p>
                </div>
              )
            ))}
          </div>
        </div>
        <div className="card">
          <div className="card-title">
            <div className="card-title-l-line"></div>
            <h4>Deposit Channel</h4>
          </div>
          <div className="card-body">
            <div className="account-item active">
              <p className="text-light">Cash Out </p>
            </div>
            <div className="account-item">
              <p className="text-light">Send Money </p>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-title">
            <div className="card-title-l-line"></div>
            <h4>Amount</h4>
          </div>
          <div className="card-body">
            {amounts.map((amount) => (
              <div
                key={amount}
                className={`account-item ${activeAmount === amount ? 'active' : ''}`}
                onClick={() => handleAmountClick(amount)}
              >
                <p className="text-light">{amount}</p>
              </div>
            ))}
          </div>
          <div className='amount-selected'>
            <span className='text-success'>৳</span>
            <p className='px-2'>{activeAmount}</p>
          </div>
        </div>

        {/* Pass state with Link */}
        <Link
          className="btn"
          to={{
            pathname: '/payment-request',
            state: { paymentMethod: activeItem, amount: activeAmount, tab: activeTab }
          }}
        >
          Next
        </Link>
      </div>

      {/* Withdraw Tab */}
      <div className={`fund-body ${activeTab.toLowerCase()} ${activeTab === 'Withdraw' ? 'open' : ''}`}>
        <MainBalance />
        <div className="card">
          <div className="card-title">
            <div className="card-title-l-line"></div>
            <h4>Payment Method</h4>
          </div>
          <div className="card-body">
            {items.map((item) => (
              // Render only active payment methods
              item.status === 'active' && (
                <div
                  key={item.id}
                  className={`account-item ${activeItem.name === item.name ? 'active' : ''}`}
                  onClick={() => handleItemClick(item.name, item.id)}
                >
                  <img
                    src={`https://nofa365.com/${item.icon}`}
                    alt={item.name}
                    className={`item-icon ${item.name === 'Bank' ? 'bank' : ''}`}
                  />
                  <p className="text-light">{item.name}</p>
                </div>
              )
            ))}
          </div>
        </div>

        <div className="card">
          <div className="card-title">
            <div className="card-title-l-line"></div>
            <h4>Amount</h4>
          </div>
          <div className="card-body">
            {amounts.map((amount) => (
              <div
                key={amount}
                className={`account-item ${activeAmount === amount ? 'active' : ''}`}
                onClick={() => handleAmountClick(amount)}
              >
                <p className="text-light">{amount}</p>
              </div>
            ))}
          </div>
          <div className='amount-selected'>
            <span className='text-success'>৳</span>
            <p className='px-2'>{activeAmount}</p>
          </div>
        </div>
        <div className='password-notice text-start my-3'>
          <div className='d-flex algin-items-center'>
          <FontAwesomeIcon icon={faInfo} /> <span className='px-2'>Dear all member, to speed up your deposit process, kindly follow these steps :</span>

          </div>
          <ul className='password-reset-lists'>
              <li>Verify the phone number you used for deposit.</li>
              <li>Input the correct reference number.</li>
              <li>Process only selected amount.</li>
              <li>Attach the successful Slip.</li>
              <li>Reminder:Do not save the phone number displayed on Baji website on your device to avoid losing money.</li>
              
          </ul>
      </div>
        <button className='btn' onClick={() => console.log('Withdraw logic here')}>Submit</button>
      </div>
    </div>
  );
}

export default Funds;