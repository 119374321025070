import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faMessage, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

function PasswordRest() {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showReTypePassword, setShowReTypePassword] = useState(false);

  const toggleOldPasswordVisibility = () => {
    setShowOldPassword(!showOldPassword);
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleReTypePasswordVisibility = () => {
    setShowReTypePassword(!showReTypePassword);
  };
 

  return (
    <div className="funds profile reset-pass open">
      <div className="fund-header">
        <div className="fund-action-bar">
      
        <Link to={'/account'}><FontAwesomeIcon  icon={faArrowLeft} /></Link>
         
          <h6>Password Reset</h6>
          <div className="">
            <FontAwesomeIcon icon={faMessage} />
            <p>Help</p>
          </div>
        </div>
      </div>
      <div className="fund-body">
        <div className="card">
          <div className="input-group justify-content-around">
            <label className="text-light">Old Password</label>
            <div className="password-wrapper">
              <input
                placeholder="Old Password"
                type={showOldPassword ? "text" : "password"}
                name="oldPassword"
              />
              
            </div>
            <FontAwesomeIcon
                className='text-white'
                icon={showOldPassword ? faEyeSlash : faEye}
                onClick={toggleOldPasswordVisibility}
              />
          </div>
          <div className="input-group justify-content-around">
            <label className="text-light">New Password</label>
            <div className="password-wrapper">
              <input
                placeholder="New Password"
                type={showNewPassword ? "text" : "password"}
                name="newPassword"
              />
             
            </div>
            <FontAwesomeIcon
                className='text-white'
                icon={showNewPassword ? faEyeSlash : faEye}
                onClick={toggleNewPasswordVisibility}
              />
          </div>
          <div className="input-group justify-content-around">
            <label className="text-light">Re-Type Password</label>
            <div className="password-wrapper">
              <input
                placeholder="Re-Type Password"
                type={showReTypePassword ? "text" : "password"}
                name="retypePassword"
              />
             
            </div>
            <FontAwesomeIcon
                className='text-white'
                icon={showReTypePassword ? faEyeSlash : faEye}
                onClick={toggleReTypePasswordVisibility}
              />
          </div>
        </div>
        <button className="btn">Submit</button>
      </div>
    </div>
  );
}

export default PasswordRest;
