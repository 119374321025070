import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const StoreCode = ({ codeType }) => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const params = new URLSearchParams(location.search); // Get the query string from the URL
        const code = params.get(codeType); // Extract the correct parameter from the URL

        if (code) {
            localStorage.setItem(codeType, code); // Save the code to localStorage
            console.log(`${codeType} saved:`, code);
        } else {
            alert(`No ${codeType} found in the URL`); // Handle case when the code is not in the URL
        }

        navigate('/'); // Redirect after storing the code
    }, [location, navigate, codeType]);

    return null; // No UI needed
};

export default StoreCode;
