import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import MainBalance from '../common/MainBalance';
import { Modal } from 'react-bootstrap';

function Transfer() {
    const [errorMessage, setErrorMessage] = useState(null);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false); // State for success modal
    const [isEligible, setIsEligible] = useState(false); // State for eligibility
    const [amount, setAmount] = useState(''); // State for the transfer amount
    const [username, setUsername] = useState(''); // State for username
    const [successMessage, setSuccessMessage] = useState(''); // State for success message

    const remoteId = localStorage.getItem('remote_id');
    const token = localStorage.getItem('token');

    const checkUserEligibility = async () => {
        try {
            const response = await fetch(`https://nofa365.com/api/userwithdrawcheck?remote_id=${remoteId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            const data = await response.json();

            if (!response.ok) {
                setErrorMessage(data.error || 'An error occurred.');
                setShowErrorModal(true);
                return false;
            }

            if (data.success) {
                setIsEligible(true); // Set eligibility to true
                return true;
            } else {
                setErrorMessage(data.error);
                setShowErrorModal(true);
                return false;
            }
        } catch (error) {
            setErrorMessage('An error occurred while checking withdrawal eligibility.');
            setShowErrorModal(true);
            return false;
        }
    };

    // Use useEffect to check eligibility on component mount
    useEffect(() => {
        checkUserEligibility();
    }, []);

    // Function to handle modal close
    const handleCloseErrorModal = () => {
        setShowErrorModal(false);
        setErrorMessage(null);
    };

    const handleCloseSuccessModal = () => {
        setShowSuccessModal(false);
        setSuccessMessage(''); // Reset success message
    };

    // Function to handle transfer submission
    const handleTransfer = async () => {
        if (!amount || !username) {
            setErrorMessage('Please enter both username and amount.');
            setShowErrorModal(true);
            return;
        }

        try {
            const response = await fetch('https://nofa365.com/api/transfer', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    username,
                    remote_id: remoteId,
                    amount: parseFloat(amount), // Convert amount to a number
                }),
            });

            const data = await response.json();

            if (!response.ok) {
                setErrorMessage(data.error || 'An error occurred during the transfer.');
                setShowErrorModal(true);
                return;
            }

            // Handle successful transfer response
            setSuccessMessage(data.success); // Set success message
            setShowSuccessModal(true); // Show success modal
            // Optionally reset the input fields after a successful transfer
            setAmount('');
            setUsername('');
        } catch (error) {
            setErrorMessage('An error occurred while processing the transfer.');
            setShowErrorModal(true);
        }
    };

    return (
        <div className="my-vip open">
            <div className="my-vip-header d-flex justify-content-between align-items-center">
                <Link to={'/account'}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                </Link>
                <h6>Transfer</h6>
                <div></div>
            </div>
            <div className="my-vip-body">
                <MainBalance />
                <div className="content-title text-start mx-3">
                    <h4>Transfer</h4>
                </div>
                <div className="vip-cards">
                    <div className="vip-card-body">
                        <div className="d-flex">
                            <div className="col-3">
                                <video
                                    width="100%"
                                    height="100%"
                                    autoPlay
                                    playsInline
                                    loop
                                    muted
                                    poster="https://img.m167cw.com/mcw/h5/assets/images/vip/coin-rotate-silver.png"
                                    style={{ width: '100%', height: 'auto' }}
                                >
                                    <source
                                        src="https://img.m167cw.com/mcw/h5/assets/images/vip/coin-rotate-silver-alpha.mov"
                                        type="video/quicktime"
                                    />
                                    <source
                                        src="https://img.m167cw.com/mcw/h5/assets/images/vip/coin-rotate-silver-alpha.webm"
                                        type="video/webm"
                                    />
                                </video>
                            </div>
                            <div className="col-9 vp">
                                <div className="d-flex justify-content-between">
                                    <p className="text-white text-start">Amount</p>
                                    <p className="text-white text-end">Minimum Amount Required: 100 to 100000 ৳ BDT</p>
                                </div>
                                <input 
                                    placeholder="0" 
                                    name='amount' 
                                    type="number" 
                                    value={amount} // Bind value to state
                                    onChange={(e) => setAmount(e.target.value)} // Update state on change
                                    disabled={!isEligible} // Disable input if not eligible
                                />
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="col-3">
                                <video
                                    width="100%"
                                    height="100%"
                                    autoPlay
                                    playsInline
                                    loop
                                    muted
                                    poster="https://img.b112j.com/bj/h5/assets/images/vip/bdt/coin-rotate-gold.png"
                                    style={{ width: '100%', height: 'auto' }}
                                >
                                    <source
                                        src="https://img.b112j.com/bj/h5/assets/images/vip/bdt/coin-rotate-gold-alpha.mov"
                                        type="video/quicktime"
                                    />
                                    <source
                                        src="https://img.b112j.com/bj/h5/assets/images/vip/bdt/coin-rotate-gold-alpha.webm"
                                        type="video/webm"
                                    />
                                </video>
                            </div>
                            <div className="col-9 vp">
                                <div className="d-flex justify-content-between">
                                    <p className="text-white text-start">Username</p>
                                </div>
                                <input 
                                    placeholder="Username" 
                                    name='username'  
                                    type="text" 
                                    value={username} // Bind value to state
                                    onChange={(e) => setUsername(e.target.value)} // Update state on change
                                    readOnly={!isEligible}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="my-vip-footer">
                <div 
                    onClick={handleTransfer} 
                    className={`vp-footer ${amount && username ? 'linier-bg' : ''}`} // Conditionally add linier-bg class
                    disabled={!isEligible}
                >
                    Transfer
                </div>
            </div>

            {/* Bootstrap Modal for Error Messages */}
            <Modal className='msg' show={showErrorModal} onHide={handleCloseErrorModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{errorMessage}</p>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleCloseErrorModal}>Close</button>
                </Modal.Footer>
            </Modal>

            {/* Bootstrap Modal for Success Messages */}
            <Modal className='msg' show={showSuccessModal} onHide={handleCloseSuccessModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Success</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{successMessage}</p>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleCloseSuccessModal}>Close</button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Transfer;
