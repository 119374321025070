import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faMessage, faFilter, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { Link } from 'react-router-dom';

function formatDateTime(dateString) {
  const date = new Date(dateString);
  const options = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    
  };
  return date.toLocaleString('en-US', options);
}

function formatTime(dateString) {
  const date = new Date(dateString);
  const options = {
    hour: '2-digit',
    minute: '2-digit'
  };
  return date.toLocaleTimeString('en-US', options);
}

// Utility to filter transactions by date range
function filterByDateRange(transactions, range) {
  const now = new Date();
  return transactions.filter(transaction => {
    const trxDate = new Date(transaction.created_at);
    switch (range) {
      case 'Today':
        return trxDate.toDateString() === now.toDateString();
      case 'Last Week':
        const lastWeek = new Date(now);
        lastWeek.setDate(now.getDate() - 7);
        return trxDate >= lastWeek && trxDate <= now;
      case 'Last Month':
        const lastMonth = new Date(now);
        lastMonth.setMonth(now.getMonth() - 1);
        return trxDate >= lastMonth && trxDate <= now;
      case 'This Year':
        return trxDate.getFullYear() === now.getFullYear();
      case 'All':
      default:
        return true;
    }
  });
}

// Group transactions by date
function groupByDate(transactions) {
  const grouped = {};
  transactions.forEach(transaction => {
    const date = new Date(transaction.created_at).toDateString();
    if (!grouped[date]) {
      grouped[date] = [];
    }
    grouped[date].push(transaction);
  });
  return grouped;
}

function TransactionRecord() {
  const [transactionData, setTransactionData] = useState([]);
  const [activeTab, setActiveTab] = useState('Settled');
  const [statusFilter, setStatusFilter] = useState('All');
  const [actionFilter, setActionFilter] = useState('All');
  const [dateFilter, setDateFilter] = useState('Today');

  const handleOpenFilter = () => {
    const bodyElement = document.querySelector('.transaction-filter'); 
    if (bodyElement) {
      bodyElement.classList.add('open');
      document.body.classList.add('no-scroll'); 
    }
  };

  const handleCloseFilter = () => {
    const bodyElement = document.querySelector('.transaction-filter'); 
    if (bodyElement) {
      bodyElement.classList.remove('open');
      document.body.classList.remove('no-scroll'); 
    }
  };

  const handleStatusFilterChange = (status) => {
    setStatusFilter(status);
  };

  const handleActionFilterChange = (action) => {
    setActionFilter(action);
  };

  const handleDateFilterChange = (dateRange) => {
    setDateFilter(dateRange);
  };

  useEffect(() => {
    const fetchTransData = async () => {
      const token = localStorage.getItem('token');
      const remoteId = localStorage.getItem('remote_id');
      
      if (token && remoteId) {
        try {
          const response = await fetch(`https://nofa365.com/api/transaction/show?remote_id=${remoteId}`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });

          if (response.ok) {
            const data = await response.json();
            setTransactionData(data);
          } else {
            console.error('Failed to fetch user data with status:', response.status);
            const text = await response.text();
            console.error('Error page content:', text);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchTransData();
  }, []);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const tabFilteredTransactions = transactionData.filter(transaction => {
    if (activeTab === 'Settled') {
      return transaction.key === "1" || transaction.action === "deposit" || transaction.action === "withdraw"; // Only settled transactions
    } else if (activeTab === 'Unsettled') {
      return transaction.key === "0" || transaction.key === "2"; // Unsettled transactions
    }
    return true;
  });

  const actionFilteredTransactions = tabFilteredTransactions.filter(transaction => 
    actionFilter === 'All' || transaction.action === actionFilter.toLowerCase()
  );

  const filteredTransactions = filterByDateRange(
    actionFilteredTransactions.filter(transaction => 
      (statusFilter === 'All' || 
      (statusFilter === 'Success' && transaction.key === "1") || 
      (statusFilter === 'Pending' && transaction.key === "0") || 
      (statusFilter === 'Rejected' && transaction.key === "2"))
    ),
    dateFilter
  );

  const groupedTransactions = groupByDate(filteredTransactions);

  return (
   <>
    <div className="funds betting-record transaction-record open">
      <div className="fund-header">
        <div className="fund-action-bar">
          <Link to={'/account'}><FontAwesomeIcon  icon={faArrowLeft} /></Link>
          <h6>Transaction Record</h6>
          <div>
            <FontAwesomeIcon icon={faMessage} />
            <p>Help</p>
          </div>
        </div>
        <div className='fund-nav'>
          <ul className='p-3 mx-3'>
            <li
              className={`nav-item ${activeTab === 'Settled' ? 'active' : ''}`}
              onClick={() => handleTabClick('Settled')}
            >
              Settled
            </li>
            <li
              className={`nav-item ${activeTab === 'Unsettled' ? 'active' : ''}`}
              onClick={() => handleTabClick('Unsettled')}
            >
              Unsettled
            </li>
          </ul>
        </div>
      </div>

      <div className={`fund-body ${activeTab.toLowerCase()} ${activeTab === 'Settled' ? 'open' : ''}`}>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='filter-heading'>{dateFilter}</div>
          <FontAwesomeIcon onClick={handleOpenFilter} icon={faFilter} className='filter-icon' />
        </div>
        <table>
          <thead>
            <tr>
              <th>Type</th>
              <th>Amount</th>
              <th>Status</th>
              <th>TRX Date</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(groupedTransactions).map((date, index) => (
              <React.Fragment key={index}>
                <div className='p-2 text-secondary'> <FontAwesomeIcon  icon={faCalendar} /> {formatDateTime(date)}</div>
                
                {groupedTransactions[date].map((transaction, txnIndex) => (
                  <tr key={txnIndex}>
                    <th scope="row" className='text-capitalize'>{transaction.action}</th>
                    <td>{transaction.amount}</td>
                    <td>
                      {transaction.key === "1" && <span className="text-success-alert">Success</span>}
                      {transaction.key === "0" && <span className="text-pending-alert">Pending</span>}
                      {transaction.key === "2" && <span className="text-rejected-alert">Rejected</span>}
                    </td>
                    <td>{formatTime(transaction.created_at)} <FontAwesomeIcon  icon={faChevronRight} /></td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    {/* Filter Sidebar */}
    <div className="filltering transaction-filter">
      <div className="filter-header d-flex justify-content-between align-items-center text-white">
        <FontAwesomeIcon onClick={handleCloseFilter} icon={faArrowLeft} />
        <h6>Transaction Record Filter</h6>
        <div></div>
      </div>
      <div className="filter-body">
        <span className="filter-title">Status</span>
        <div className="row">
          <div 
            className={`filter-item ${statusFilter === 'Pending' ? 'active' : ''}`} 
            onClick={() => handleStatusFilterChange('Pending')}
          >
            Pending
          </div>
          <div 
            className={`filter-item ${statusFilter === 'Success' ? 'active' : ''}`} 
            onClick={() => handleStatusFilterChange('Success')}
          >
            Success
          </div>
          <div 
            className={`filter-item ${statusFilter === 'Rejected' ? 'active' : ''}`} 
            onClick={() => handleStatusFilterChange('Rejected')}
          >
            Rejected
          </div>
          <div 
            className={`filter-item ${statusFilter === 'All' ? 'active' : ''}`} 
            onClick={() => handleStatusFilterChange('All')}
          >
            All
          </div>
        </div>

        <span className="filter-title">Action</span>
        <div className="row">
          <div 
            className={`filter-item ${actionFilter === 'Deposit' ? 'active' : ''}`} 
            onClick={() => handleActionFilterChange('Deposit')}
          >
            Deposit
          </div>
          <div 
            className={`filter-item ${actionFilter === 'Withdraw' ? 'active' : ''}`} 
            onClick={() => handleActionFilterChange('Withdraw')}
          >
            Withdraw
          </div>
          <div 
            className={`filter-item ${actionFilter === 'All' ? 'active' : ''}`} 
            onClick={() => handleActionFilterChange('All')}
          >
            All
          </div>
        </div>

        <span className="filter-title">Date Range</span>
        <div className="row">
          <div 
            className={`filter-item ${dateFilter === 'Today' ? 'active' : ''}`} 
            onClick={() => handleDateFilterChange('Today')}
          >
            Today
          </div>
          <div 
            className={`filter-item ${dateFilter === 'Last Week' ? 'active' : ''}`} 
            onClick={() => handleDateFilterChange('Last Week')}
          >
            Last Week
          </div>
          <div 
            className={`filter-item ${dateFilter === 'Last Month' ? 'active' : ''}`} 
            onClick={() => handleDateFilterChange('Last Month')}
          >
            Last Month
          </div>
          <div 
            className={`filter-item ${dateFilter === 'This Year' ? 'active' : ''}`} 
            onClick={() => handleDateFilterChange('This Year')}
          >
            This Year
          </div>
          <div 
            className={`filter-item ${dateFilter === 'All' ? 'active' : ''}`} 
            onClick={() => handleDateFilterChange('All')}
          >
            All
          </div>
        </div>
      </div>
    </div>
   </>
  );
}

export default TransactionRecord;
