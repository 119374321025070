import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';

function BottomNav() {
    const [isLoggedIn] = useState(!!localStorage.getItem('token'));
    const [showModal, setShowModal] = useState(false); // State to control modal visibility
    const [language, setLanguage] = useState('English'); // State for selected language

    // Function to handle showing the modal
    const handleShowModal = () => setShowModal(true);

    // Function to handle closing the modal
    const handleCloseModal = () => setShowModal(false);

    // Function to trigger Google Translate
    const translatePage = (lang) => {
        if (lang === 'Bangla') {
            const googleTranslateElement = document.querySelector('.goog-te-combo');
            if (googleTranslateElement) {
                googleTranslateElement.value = 'bn'; // Set the language to Bangla
                googleTranslateElement.dispatchEvent(new Event('change')); // Trigger change event
            }
        } else {
            const googleTranslateElement = document.querySelector('.goog-te-combo');
            if (googleTranslateElement) {
                googleTranslateElement.value = 'en'; // Set the language to English (default)
                googleTranslateElement.dispatchEvent(new Event('change')); // Trigger change event
            }
        }
        setLanguage(lang); // Set the current language
        handleCloseModal(); // Close the modal
    };

    return (
        <div className="bottom-nav">
            {isLoggedIn ? (
                <ul>
                    <li className="nav-item">
                        <Link to="/">
                            <img src="/icon/toolbar-icon-home.svg" alt=""/>
                            <p className='text-white'>Home</p> 
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to={'/promotion'}> 
                            <img src="/icon/toolbar-icon-promotion.svg" alt=""/>
                            <p className='text-white'>Promotions</p>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to='/deposit'>
                            <img src="/icon/toolbar-icon-deposit.svg" alt=""/>
                            <p className='text-white'>Deposit</p>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to={'/account'}>
                            <img src="/icon/toolbar-icon-mine.svg" alt=""/>
                            <p className='text-white'>My Account</p>
                        </Link>
                    </li>
                </ul>
            ) : (
                <>
                    <div className='not-login-bottom-nav d-flex'>
                        <div className='lang-bottom-menu' onClick={handleShowModal}> {/* Open modal on click */}
                            <img src='./icon/mobile-bd.png' alt='Bangla'/>
                            <p>{language === 'Bangla' ? 'BDT বাংলা' : 'BDT English'}</p>
                        </div>
                        <div className='bottom-action'>
                            <div className='login-bottom-menu'>
                                <Link to={'/login'}>Login</Link>
                            </div>
                            <div className='signup-bottom-menu'>
                                <Link to={'/register'}>Signup</Link>
                            </div>
                        </div>
                    </div>
                </>
            )}

            {/* Bootstrap Modal */}
            <Modal className='msg' show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Choose Language</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                   <div className="lan-modal">
                        <div className='currency-icon'>
                            <img src='./icon/mobile-bd.png' alt='icon'/>
                            <span>৳ BDT</span>
                        </div>
                        <div className={`lan-item ${language === 'English' ? 'active' : ''}`} onClick={() => translatePage('English')}>
                            English
                        </div>
                        <div className={`lan-item ${language === 'Bangla' ? 'active' : ''}`} onClick={() => translatePage('Bangla')}>
                            বাংলা
                        </div>
                   </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default BottomNav;
