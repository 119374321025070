import React from "react";
import CasinoBanner from "../Banner/CasinoBanner";
import CasinoList from "../common/CasinoList";

function CasinoPage(){
   return(
    <div className="casino max-width">

    <CasinoList/>
    </div>
    
   );
}
export default CasinoPage;