import React from 'react';
import { Modal } from 'react-bootstrap';

const MessageModal = ({ show, handleClose, message }) => {
    return (
        <Modal className='msg' show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Message Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>{message}</Modal.Body>
        </Modal>
    );
};

export default MessageModal;
