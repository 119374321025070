import React from "react";

function CasinoBanner(){
    return(
        <><div className="casino-banner">
        <img src="./sliders/image_53685.jpg" alt=""/>
    </div>
   
    </>
        
    );
}
export default CasinoBanner;