import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import { Modal, Button } from 'react-bootstrap'; // Import Bootstrap components

function CasinoList() {
    const [games, setGames] = useState([]);
    const [filteredGames, setFilteredGames] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [isMobile, setIsMobile] = useState(false);
    const [providers, setProviders] = useState([]);
    const [systems, setSystems] = useState([]);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false); // State for modal visibility
    
    const navigate = useNavigate();
    const location = useLocation();
    
    const system = location.state?.system;
    const type = location.state?.type; 
    const title = location.state?.title; 
    const [activeProvider, setActiveProvider] = useState(system); 

    useEffect(() => {
        if (type && system) {
            fetchGames(system);
            fetchSystems(type);
        } else {
            console.error("Type or system is missing", { type, system });
        }

        const isMobileDevice = window.innerWidth <= 768;
        setIsMobile(isMobileDevice);
    }, [type, system]);

    const fetchGames = (currentSystem) => {
        fetch(`https://nofa365.com/api/get-games?type=${type}&system=${currentSystem}`)
            .then(response => response.json())
            .then(data => {
                setGames(data.games);
                setFilteredGames(data.games);
                console.log(data);
            })
            .catch(error => {
                console.error('Error fetching games:', error);
                setError('Failed to fetch games');
            });
    };

    const fetchSystems = async (type) => {
        try {
            const response = await fetch(`https://nofa365.com/api/get-system?type=${type}`);
            const data = await response.json();
            if (response.ok) {
                setSystems(data.systems || []);
                fetchProviders(data.systems);
            } else {
                setError(data.message || 'Failed to fetch systems');
            }
        } catch (error) {
            console.error('Error fetching systems:', error);
            setError('An unexpected error occurred while fetching systems');
        }
    };

    const fetchProviders = async (systems) => {
        try {
            const response = await fetch(`https://nofa365.com/api/get-provider?system=${JSON.stringify(systems)}`);
            const data = await response.json();
            if (response.ok) {
                setProviders(data.data || []);
            } else {
                setError(data.message || 'Failed to fetch providers');
            }
        } catch (error) {
            console.error('Error fetching providers:', error);
            setError('An unexpected error occurred while fetching providers');
        }
    };

    const handleSearch = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchQuery(query);
        const filtered = games.filter(game => game.name.toLowerCase().includes(query));
        setFilteredGames(filtered);
    };

    const handleGameClick = async (gameId) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                setError('User not logged in');
                return;
            }

            const response = await fetch('https://nofa365.com/api/get-game-direct', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    game_id: gameId,
                    lang: 'en',
                    play_for_fun: false,
                    home_url: 'https://worldbaji.live',
                }),
            });

            const data = await response.json();
            if (response.ok) {
                navigate('/game', { state: { iframeUrl: data.response.response.url } });
            } else {
                setError(data.message || 'An unexpected error occurred');
            }
        } catch (error) {
            setError('An unexpected error occurred');
        }
    };

    const handleProviderClick = (providerSystem) => {
        fetchGames(providerSystem);
        setActiveProvider(providerSystem); 
    };

    const handleShowModal = () => setShowModal(true); // Function to show modal
    const handleCloseModal = () => setShowModal(false); // Function to close modal

    const imageExtensions = ['jpg', 'png', 'jpeg', 'webp']; // List of possible extensions

// Function to find the correct image path
const getImageSrc = (gameCode) => {
    for (let ext of imageExtensions) {
        const imagePath = `./poster/${gameCode}.${ext}`;
        if (imageExists(imagePath)) {
            return imagePath; // Return as soon as the correct image is found
        }
    }
    return './poster/default.jpg';
};


// Function to check if the image exists (using fetch)
const imageExists = (imagePath) => {
    try {
        const img = new Image();
        img.src = imagePath;
        return img.complete && img.naturalHeight !== 0;
    } catch (error) {
        return false;
    }
};

    return (
        <div className="casino">
            <div className="action-bar">
                <div className="casino-filter-icon">
                    <li onClick={() => handleProviderClick('')} 
                    className={`nav-item ${activeProvider === '' ? 'active' : ''}`} >
                        <img className="p-3" src="https://img.b112j.com/bj/h5/assets/images/icon-set/icon-filter-all.svg?v=1727770742398" alt="Filter" />
                    </li>
                    {providers.map((provider, index) => (
                    <li
                        key={index}
                        className={`nav-item ${activeProvider === provider.platform ? 'active' : ''}`}
                        onClick={() => handleProviderClick(provider.platform)}
                    >
                        <img src={`./icon/platform/${provider.platform}.png`} alt={provider.platform} />
                    </li>
                ))} 
                </div>
               
                <div className="search-btn" onClick={handleShowModal}> {/* Show modal on click */}
                    <FontAwesomeIcon icon={faSearch} className="search-icon" />
                </div>
            </div>
            
            <span className="text-danger">{error}</span>
            <div className='title'>
                <h4 className='text-light text-start'>{title}</h4>
            </div>
            <div className="row casino-card">
            {filteredGames.map(game => (
                <div 
                    key={game.code} 
                    onClick={() => handleGameClick(game.code)} 
                    className="col-lg-2 col-6 my-2 casino-list"
                >
                    <div className="casino-item">
                        <img src={getImageSrc(game.code)} alt={game.name} />
                        <div className="games-title">
                            <p>{game.name}</p>
                            <FontAwesomeIcon icon={faHeart} className="text-white" />
                        </div>
                    </div>
                </div>
            ))}
            </div>

            {/* Bootstrap Modal */}
            <Modal show={showModal} onHide={handleCloseModal}>
               
                <Modal.Body>
                <div className="search-bar">
                <FontAwesomeIcon icon={faSearch} className="search-icon" />
                <input
                    placeholder="Search"
                    type="search"
                    name="search"
                    value={searchQuery}
                    onChange={handleSearch}
                />
            </div>

                <div className="filter-body">
                    <span className="filter-title">Status</span>
                    <div className="row">
                    <div 
                                    
                                    className={`filter-item ${activeProvider === '' ? 'active' : ''}`} 
                                    onClick={() => handleProviderClick('')}
                                    >
                                    ALL
                                    </div>
                    {providers.map((provider, index) => (
                                    <div 
                                    key={index}
                                    className={`filter-item ${activeProvider === provider.system ? 'active' : ''}`} 
                                    onClick={() => handleProviderClick(provider.system)}
                                    >
                                    {provider.name}
                                    </div>
                                ))} 
                        

                        
                    </div>
     
      
       
      
     </div>
                   
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default CasinoList;
