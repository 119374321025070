import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faMessage, faInfo } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import MainBalance from '../common/MainBalance';
import { Modal, Button } from 'react-bootstrap'; // Import Bootstrap modal

function Withdraw() {
  const [activeItem, setActiveItem] = useState('');  // Initial default payment method
  const [activeAmount, setActiveAmount] = useState(null);
  const [items, setItems] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false); // State to handle modal visibility
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);


  const amounts = ['100', '500', '1,000', '2,000'];
  const remoteId = localStorage.getItem('remote_id');
  const token = localStorage.getItem('token');

  // Fetch payment methods on component mount
  useEffect(() => {
    fetch('https://nofa365.com/api/payment-methods')
      .then(response => response.json())
      .then(data => setItems(data))
      .finally(setIsLoading(false))
      .catch(error => console.error('Error fetching payment methods:', error));
  }, []);

  const handleItemClick = (id) => {
    setActiveItem({ id });
    console.log(id);
  };

  const handleAmountClick = (amount) => {
    const numericAmount = parseInt(amount.replace(/,/g, ''), 10);
    setActiveAmount(prevAmount => {
      if (prevAmount !== null) {
        const numericPrevAmount = parseInt(prevAmount.replace(/,/g, ''), 10);
        return (numericPrevAmount + numericAmount).toLocaleString();
      } else {
        return numericAmount.toLocaleString();
      }
    });
  };

  const checkUserEligibility = async () => {
    try {
      const response = await fetch(`https://nofa365.com/api/userwithdrawcheck?remote_id=${remoteId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      const data = await response.json();
      
      if (!response.ok) {
        setErrorMessage(data.error || 'An error occurred.');
        setShowErrorModal(true); // Show modal if there's an error
        return false;
      }

      if (data.success) {
        return true;
      } else {
        setErrorMessage(data.error);
        setShowErrorModal(true); // Show modal if there's an error
        return false;
      }
    } catch (error) {
      setErrorMessage('An error occurred while checking withdrawal eligibility.');
      setShowErrorModal(true); // Show modal if there's an error
      return false;
    }
  };

  const handleProceed = async () => {
    const eligible = await checkUserEligibility();
    if (eligible) {
      navigate('/withdraw-request', { state: { paymentMethod: activeItem, amount: activeAmount?.replace(/,/g, '') } });
    }
  };

  const handleClose = () => setShowErrorModal(false); // Handle closing of modal

  if (isLoading) {
    return <div className="loading-container">
    <div className="loading-spinner"></div>
  </div>;
  }

  return (
    <div className="wallet on">
      <div className="fund-header">
        <div className="fund-action-bar">
          <Link to={'/account'}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </Link>
          <h6>Withdraw</h6>
          <div className="">
            <FontAwesomeIcon icon={faMessage} />
            <p>Help</p>
          </div>
        </div>
        <div className="fund-nav">
          <ul className='px-3 py-3 mx-3'>
            <li className='nav-item'>
              <Link className='nav-item' to={'/deposit'}>Deposit</Link>
            </li>
            <li className='nav-item active'>
              <Link className='nav-item' to={'/withdraw'}>Withdraw</Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="fund-body">
        <MainBalance />
        <div className="card">
          <div className="card-title">
            <div className="card-title-l-line"></div>
            <h4>Payment Method</h4>
          </div>
          <div className="card-body">
            {items.map((item) => (
              item.status === 'active' && (
                <div
                  key={item.id}
                  className={`account-item ${activeItem.id === item.id ? 'active' : ''}`}
                  onClick={() => handleItemClick(item.id)}
                >
                  <img
                    src={`https://nofa365.com/storage/${item.icon}`}
                    alt={item.name}
                    className={`item-icon ${item.name === 'Bank' ? 'bank' : ''}`}
                  />
                  <p className="text-light">{item.name}</p>
                </div>
              )
            ))}
          </div>
        </div>

        <div className="card">
          <div className="card-title">
            <div className="card-title-l-line"></div>
            <h4>Amount</h4>
          </div>
          <div className="card-body">
            {amounts.map((amount) => (
              <div
                key={amount}
                className={`account-item ${activeAmount === amount ? 'active' : ''}`}
                onClick={() => handleAmountClick(amount)}
              >
                <p className="text-light">{amount}</p>
              </div>
            ))}
          </div>
          <div className='amount-selected'>
            <span className='text-success'>৳</span>
            <p className='px-2'>{activeAmount}</p>
          </div>
        </div>

        <div className='password-notice text-start my-3'>
          <div className='d-flex align-items-center'>
            <FontAwesomeIcon icon={faInfo} /> 
            <span className='px-2'>Dear all members, to speed up your Withdrawal process, kindly follow these steps:</span>
          </div>
          <ul className='password-reset-lists'>
            <li>Verify the phone number you used for Withdrawal.</li>
            <li>Please Set your Date Of Birth.</li>
            <li>Make sure you completed your Turnover.</li>
          </ul>
        </div>

        <button className="btn" onClick={handleProceed}>
          Go to Payment Request
        </button>
      </div>

      {/* Bootstrap Modal for Error Message */}
      <Modal className='msg' show={showErrorModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{errorMessage}</Modal.Body>
        
      </Modal>
    </div>
  );
}

export default Withdraw;
