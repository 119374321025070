import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";

const PasswordForget = () => {
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        phone: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here, e.g., API call for password reset
        console.log(formData);
    };

    return (
        <div className="register login open">
            <div className="register-header text-white">
                <Link to={'/login'}><FontAwesomeIcon icon={faAngleLeft} className="px-3" /></Link>
                <h6>Forgot Password</h6>
                <span></span>
            </div>
            <div className="register-body">
                <div className="signup-banner">
                    <img src="./bg/en-sponsor.jpg" className="w-100" alt="Sponsor Banner" />
                </div>

                <div className="p-3">
                    <form onSubmit={handleSubmit}>
                        <div className="card">
                            <div className="bottom-input-group">
                                <label>Username</label>
                                <div className="input-field-group">
                                    <input
                                        name="username"
                                        type="text"
                                        placeholder="Username"
                                        value={formData.username}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="bottom-input-group">
                                <label>Phone</label>
                                <div className="input-field-group">
                                    <input
                                        name="phone"
                                        type="text"
                                        placeholder="Phone"
                                        value={formData.phone}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="bottom-input-group">
                                <label>Email</label>
                                <div className="input-field-group">
                                    <input
                                        name="email"
                                        type="text"
                                        placeholder="Email Address"
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <button type="submit" className="btn">Submit</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default PasswordForget;
