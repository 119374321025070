import React, { useState, useEffect } from 'react';
import { faArrowLeft, faLink, faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import QRCode from 'qrcode.react';

const ReferralProgram = () => {
    const [referralCode, setReferralCode] = useState('');
    const [errorMessage, setErrorMessage] = useState(''); // State to handle error messages

    useEffect(() => {
        const fetchUserData = async () => {
            const token = localStorage.getItem('token');
            const remoteId = localStorage.getItem('remote_id');

            try {
                const response = await fetch(`https://nofa365.com/api/user/show?remote_id=${remoteId}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch user data');
                }

                const data = await response.json();
            
                if (data.referral_code) {
                    setReferralCode(data.referral_code); // Set the referral code directly
                } else {
                    throw new Error('Referral code not found in API response');
                }
            } catch (error) {
                console.error(error);
                setErrorMessage(error.message); // Set the error message to be displayed
            }
        };

        fetchUserData();
    }, []);

    const copyLink = () => {
        const link = `https://worldbaji.live/referral_code?referral_code=${referralCode}`;
        navigator.clipboard.writeText(link).then(() => {
            alert('Referral link copied to clipboard!');
        }).catch(error => {
            console.error('Failed to copy referral link:', error);
            alert('Failed to copy referral link');
        });
    };

    const shareLink = () => {
        const link = `https://worldbaji.live/referral_code?referral_code=${referralCode}`;
        if (navigator.share) {
            navigator.share({
                title: 'Referral Program',
                text: 'Join World Baji and earn rewards with my referral code!',
                url: link,
            }).then(() => {
                console.log('Successfully shared');
            }).catch((error) => {
                console.error('Error sharing:', error);
                alert('Failed to share link');
            });
        } else {
            alert('Web Share API is not supported in your browser.');
        }
    };

    return (
        <div className="referral-program open">
            <div className="row header py-2">
                <button className="back-button col-lg-4 col-3">
                    <Link to={'/account'}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </Link>
                </button>
                <h6 className='col-lg-4 col-6'>Referral Program</h6>
            </div>
            <div className="tab-content">
                {errorMessage && <p className="error-message">{errorMessage}</p>} {/* Display error message if exists */}
                <div className="referral-content">
                    <div className='ref-content-header'>
                        <img src='./logos/logo.png' className='ref-logo' alt='' />
                        <span className='ref-title'>REFER YOUR FRIENDS</span>
                        <span className='ref-title text-warning'>AND EARN</span>
                    </div>
                    <div className='ref-content m-3 '>
                        <div className='ref-content-qr'>
                            <p>Your Referral Code</p>
                            <h3 className='text-warning my-3'>{referralCode} <FontAwesomeIcon icon={faCopy} onClick={copyLink} /></h3>
                            <QRCode value={`https://worldbaji.live/referral_code?referral_code=${referralCode}`} size={128} />
                            <div className='qr-action my-3'>
                                <button onClick={copyLink}>Copy Link <FontAwesomeIcon icon={faLink} /></button>
                                <button onClick={shareLink}>Share</button> {/* Added onClick handler for sharing */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReferralProgram;
