import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './App.css';
import './assets/css/TopNav.css';
import './assets/css/HeaderNav.css';
import './assets/css/NavMenu.css';
import './assets/css/Footer.css';
import './assets/css/Casino.css';
import './assets/css/Reponsive.css';
import './assets/css/Account.css';
import './assets/css/Fund.css';
import './assets/css/Promotion.css';
import './assets/css/Clone.css';
import './assets/css/Login.css';
import Home from './components/Pages/Home';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import TopNav from './components/NavBar/TopNav';
import HeaderNav from './components/NavBar/HeaderNav';
import NavMenu from './components/NavBar/NavMenu';
import BottomNav from './components/NavBar/BottomNav';
import TapBar from './components/NavBar/TapBar';
import CasinoPage from './components/Pages/Casino';
import Funds from './components/modals/Funds';
import Account from './components/modals/Account';
import TransactionRecord from './components/modals/TransactionRecord';
import Profile from './components/modals/Profile';
import PasswordRest from './components/modals/PasswordRest';
import MyAccount from './components/Pages/MyAccount';
import Poker from './components/Pages/Poker';
import VideoPoker from './components/Pages/VideoPoker';
import Slot from './components/Pages/Slot';
import Table from './components/Pages/Table';
import GameLunch from './components/luncher/Game';
import ReferralProgram from './components/modals/ReferralProgram';
import Register from './components/Pages/Register';
import Login from './components/Pages/Login';
import Promotion from './components/Pages/Promotion';
import Crashers from './components/Pages/Crasher';
import Sports from './components/luncher/Sports';
import MyVip from './components/modals/MyVIP';
import BettingRecord from './components/modals/BettingRecord';
import Turnover from './components/modals/Turnover';
import Inbox from './components/modals/inbox';
import PaymentRequest from './components/Pages/payment-request';
import Deposit from './components/funds/Deposit';
import Withdraw from './components/funds/withdraw';
import StoreCode from './StoreCodeComponents';
import WithdrawRequest from './components/modals/WithdrawRequest';
import PasswordForget from './components/Pages/PasswordForGot';
import Transfer from './components/modals/Transfer';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token')); // Initialize based on token presence
  const [balance, setBalance] = useState(localStorage.getItem('balance') || 0); // Initialize balance
  const [lastReloadTime, setLastReloadTime] = useState(Date.now()); // Track last reload time
  const canReload = () => {
    const currentTime = Date.now();
    const reloadInterval = 5000; // 5 seconds in milliseconds
    if (currentTime - lastReloadTime < reloadInterval) {
      return false;
    }
    setLastReloadTime(currentTime);
    return true;
  };
  const fetchBalance = async () => {
    const token = localStorage.getItem('token');
    

    if (token) {
      try {
        const response = await fetch('https://nofa365.com/api/balance', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json', // Specify content type
          },
        });

        

        if (response.ok) {
          const data = await response.json();
          
          
          localStorage.setItem('balance', data.balance);
        } else {
         
          

          // Handle unauthorized status
          if (response.status === 401) {
            
            handleLogout(); 
          }
        }
      } catch (error) {
        console.error('Error fetching balance:', error);
        
      }
    } else {
      console.warn('No token found in local storage.');
    }
  };

  const handleLogout = () => {
    
    localStorage.removeItem('token');
    localStorage.removeItem('balance');
    localStorage.removeItem('sessionid');
    localStorage.removeItem('remote_id');
    localStorage.removeItem('username');
    setIsLoggedIn(false); // Automatically set login status to false
    window.location.href = '/';
  };

  useEffect(() => {
    
    if (isLoggedIn) {
      fetchBalance(); // Fetch balance only if logged in
      const interval = setInterval(fetchBalance, 1000); // Fetch every second
      return () => clearInterval(interval); // Cleanup interval on unmount
    }
  }, [isLoggedIn]);

  // Protected route component
  const PrivateRoute = ({ element }) => {
    return isLoggedIn ? element : <Navigate to="/login" />;
  };

  return (
    <div className="App">
      <Router>
        <TopNav />
        <HeaderNav />
        <NavMenu />
        <BottomNav />
        <TapBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/casino" element={<PrivateRoute element={<CasinoPage />} />} />
          <Route path="/fund" element={<PrivateRoute element={<Funds />} />} />
          <Route path="/crash" element={<PrivateRoute element={<Crashers />} />} />
          <Route path="/account" element={<PrivateRoute element={<Account />} />} />
          <Route path="/transaction-record" element={<PrivateRoute element={<TransactionRecord />} />} />
          <Route path="/betting-record" element={<PrivateRoute element={<BettingRecord />} />} />
          <Route path="/turnover" element={<PrivateRoute element={<Turnover />} />} />
          <Route path="/profile" element={<PrivateRoute element={<Profile />} />} />
          <Route path="/password-reset" element={<PrivateRoute element={<PasswordRest />} />} />
          <Route path="/poker" element={<PrivateRoute element={<Poker />} />} />
          <Route path="/video-poker" element={<PrivateRoute element={<VideoPoker />} />} />
          <Route path="/slot" element={<PrivateRoute element={<Slot />} />} />
          <Route path="/table" element={<PrivateRoute element={<Table />} />} />
          <Route path="/my-account" element={<PrivateRoute element={<MyAccount />} />} />
          <Route path="/game" element={<PrivateRoute element={<GameLunch />} />} />
          <Route path="/referral-program" element={<PrivateRoute element={<ReferralProgram />} />} />
          <Route path="/transfer" element={<PrivateRoute element={<Transfer />} />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<PasswordForget />} />
          <Route path="/promotion" element={<Promotion />} />
          <Route path="/sports"  element={<Sports />} />
          <Route path="/vip" element={<PrivateRoute element={<MyVip />} />} />
          <Route path="/inbox" element={<PrivateRoute element={<Inbox />} />} />
          <Route path="/deposit" element={<PrivateRoute element={<Deposit />} />} />
          <Route path="/withdraw" element={<PrivateRoute element={<Withdraw />} />} />
          <Route path="/payment-request" element={<PrivateRoute element={<PaymentRequest />} />} />
          <Route path="/withdraw-request" element={<PrivateRoute element={<WithdrawRequest />} />} />
          <Route path="/referral_code" element={<StoreCode codeType="referral_code" />} />
          <Route path="/affiliate_code" element={<StoreCode codeType="affiliate_code" />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
