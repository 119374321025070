import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faRightFromBracket, faXmark} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

function Account(){
    
    const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));
    const [balance, setBalance] = useState(localStorage.getItem('balance') || 0);
    const [isLoading, setIsLoading] = useState(true);
  
    const fetchBalance = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await fetch('https://nofa365.com/api/balance', {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
  
          if (response.ok) {
            const data = await response.json();
            setBalance(data.balance);
            localStorage.setItem('balance', data.balance);
          } else {
            console.error('Failed to fetch balance with status:', response.status);
            const text = await response.text();
            console.error('Error page content:', text);
          }
        } catch (error) {
          console.error('Error fetching balance:', error);
        }
        finally {
            setIsLoading(false);
          }
      }
    };
  
    useEffect(() => {
      if (isLoggedIn) {
        fetchBalance();
        const interval = setInterval(fetchBalance, 1000);
        return () => clearInterval(interval);
      }
    }, [isLoggedIn]);
    const [userData, setUserData] = useState(null);
    useEffect(() => {
        const fetchUserData = async () => {
            const token = localStorage.getItem('token');
            const remoteId = localStorage.getItem('remote_id');
            
            if (token && remoteId) {
                try {
                    const response = await fetch(`https://nofa365.com/api/user/show?remote_id=${remoteId}`, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    });
    
                    if (response.ok) {
                        const data = await response.json();
                        
                        setUserData(data);
                        
                    } else {
                        console.error('Failed to fetch user data with status:', response.status);
                        // Optionally, read and log the response text to see the error page content
                        const text = await response.text();
                        console.error('Error page content:', text);
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };
    
        if (isLoggedIn) {
            fetchUserData();
            const interval = setInterval(fetchUserData, 1000);
            return () => clearInterval(interval);
        }
    }, [isLoggedIn]);

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('balance');
        localStorage.removeItem('sessionid');
        localStorage.removeItem('remote_id');
        localStorage.removeItem('username');
        window.location.href = '/';
      };
      if (isLoading) {
        return <div className="loading-container">
        <div className="loading-spinner"></div>
      </div>;
      }
      
    return(
      
             <div className="account">
            <div className="account-header">
                <div className="close">
                   <Link to={'/'}><FontAwesomeIcon icon={faXmark} className="px-3 icon-close" />  </Link>     
                </div>
                <div className="account-info">
                    <div className="grading-icon">
                        <img src="./icon/normal.png" alt=""/>
                    </div>
                    <div>
                    {userData ? (
                           <h4 className="text-light text-start">{userData.username}</h4>
                        ) : (
                         <p>Loading user data...</p>
                     )}
                    
                    <div className="account-vip">
                    {userData ? (
                           <Link to={'/vip'}> <p className="text-light py-1 px-3">VIP Points {'(VP)'} <span className="text-success mx-2">{userData.vip_coin}</span> | My VIP {'>>'} </p></Link> 
                        ) : (
                         <p>Loading user data...</p>
                     )}
                      
                    </div>
                    </div>

                </div>
            </div>
            <div className="account-body">
                <div  className="card">
                    <div className="card-body">
                        <p>Main Balance</p>
                        
                           
                           <p className="text-warning">৳ {balance}</p>
                       
                        
                    </div>
                </div>
                <div  className="card">
                <div className="card-title">
                           <div className="card-title-l-line"></div> 
                           <h4>Funds</h4>
                        </div>
                    <div className="card-body">
                       <div className="account-item">
                      <Link to={'/deposit'}>
                      <img src="./icon/icon-deposit.png" alt="" className="item-icon"/>
                      <p className="text-light">Deposit</p>
                      </Link>
                       
                      
                       </div>
                       <div className="account-item">
                      <Link to={'/transfer'}>
                      <img src="./icon/icon-turnover.png" alt="" className="item-icon"/>
                      <p className="text-light">Transfer</p>
                      </Link>
                       
                      
                       </div>
                       <div className="account-item">
                       <Link to={'/withdraw'}>
                        <img src="./icon/icon-withdrawal.png" alt="" className="item-icon"/>
                            <p className="text-light">withdrawal</p>
                            </Link>
                       </div>
                    </div>
                </div>
                <div  className="card">
                <div className="card-title">
                           <div className="card-title-l-line"></div> 
                           <h4>History</h4>
                        </div>
                    <div className="card-body">
                       <div className="account-item">
                        <Link to={'/betting-record'}>
                        <img src="./icon/icon-bet-records.png" alt="" className="item-icon"/>
                            <p className="text-light">Betting Records</p>
                            </Link>
                       </div>
                       <div className="account-item">
                       <Link to="/turnover"> 
                        <img src="./icon/icon-turnover.png" alt="" className="item-icon"/>
                       <p className="text-light">Turnover</p>
                       </Link>
                       
                            
                       </div>
                       <div className="account-item">
                        <Link to={'/transaction-record'}>
                        <img src="./icon/icon-records.png" alt="" className="item-icon"/>
                        <p className="text-light">Transaction Records</p>
                        </Link>
                       
                           
                       </div>
                    </div>
                </div>
                <div  className="card">
                <div className="card-title">
                           <div className="card-title-l-line"></div> 
                           <h4>Profile</h4>
                        </div>
                    <div className="card-body justify-content-center">
                       <div  className="account-item">
                       <Link to="/profile"> 
                        <img src="./icon/icon-profile.png" alt="" className="item-icon"/>
                            <p className="text-light">Personal Info</p>
                          </Link>
                       </div>
                       <div  className="account-item">
                       <Link to="/password-reset"> 
                        <img src="./icon/icon-withdrawal.png" alt="" className="item-icon"/>
                            <p className="text-light">Password Reset</p>
                        </Link>
                       </div>
                       <div  className="account-item">
                       <Link to="/inbox"> 
                        <img src="./icon/icon-inbox.png" alt="" className="item-icon"/>
                            <p className="text-light">Inbox</p>
                            </Link>
                       </div>
                       
                       <div  className="account-item">
                       <Link to="/referral-program"> 
                        <img src="./icon/icon-referral.png" alt="" className="item-icon"/>
                            <p className="text-light">Referral</p>
                        </Link>
                            
                       </div>
                    </div>
                </div>
                <div  className="card">
                <div className="card-title">
                           <div className="card-title-l-line"></div> 
                           <h4>Contract Us</h4>
                        </div>
                    <div className="card-body">
                       <div className="account-item">
                        <img src="./icon/icon-whatsapp.png" alt="" className="item-icon"/>
                            <p className="text-light">Whats App</p>
                       </div>
                       <div className="account-item">
                        <img src="./icon/icon-email.png" alt="" className="item-icon"/>
                            <p className="text-light">Email</p>
                       </div>
                       <div className="account-item">
                        <img src="./icon/icon-whatsapp.png" alt="" className="item-icon"/>
                            <p className="text-light">Facebook</p>
                       </div>
                    </div>
                </div>
                <div  className="card">
                
                   <li onClick={logout} className="text-light"> <FontAwesomeIcon icon={faRightFromBracket} className="px-3" /> LogOut</li>
                </div>
            </div>
            <div className="account-footer"></div>

        </div>
        
        
       
      
    );
}
export default Account;