import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faMessage } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import MessageModal from './MessageModal'; // Import the modal component

function Inbox() {
    const [inboxMessages, setInboxMessages] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [fullMessage, setFullMessage] = useState('');
    const isLoggedIn = !!localStorage.getItem('token');

    const fetchInboxMessages = async (page) => {
        const token = localStorage.getItem('token');
        const remoteId = localStorage.getItem('remote_id');

        try {
            const response = await fetch(`https://nofa365.com/api/inbox?remote_id=${remoteId}&page=${page}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            const data = await response.json();
            if (data.success) {
                setInboxMessages(data.data.data); // Adjusting based on the expected structure
                setTotalPages(data.data.last_page); // Assuming your API provides this
            }
        } catch (error) {
            console.error("Error fetching inbox messages:", error);
        }
    };

    const fetchFullMessage = async (id) => {
        const token = localStorage.getItem('token');
        
        try {
            const response = await fetch(`https://nofa365.com/api/inbox/${id}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            const data = await response.json();
            if (data.success) {
                setFullMessage(data.data.message); // Assuming the message structure
                setShowModal(true);
            }
        } catch (error) {
            console.error("Error fetching full message:", error);
        }
    };

    useEffect(() => {
        if (isLoggedIn) {
            fetchInboxMessages(currentPage);
            const interval = setInterval(() => fetchInboxMessages(currentPage), 10000); // Fetch every 10 seconds
            return () => clearInterval(interval);
        }
    }, [isLoggedIn, currentPage]);

    return (
        <div className="inbox open">
            <div className="inbox-header">
                <Link to={'/account'}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                </Link>
                <h6>Inbox</h6>
                <div className="">
                    <FontAwesomeIcon icon={faMessage} />
                    <p>Help</p>
                </div>
            </div>
            <div className="inbox-body">
                {inboxMessages.map((message) => {
                    const messagePreview = message.message.split(' ').slice(0, 5).join(' ') + (message.message.split(' ').length > 5 ? '...' : ''); // Preview logic
                    return (
                        <div className="inbox-item" key={message.id} onClick={() => fetchFullMessage(message.id)}>
                            <div className="inbox-item-icon">
                                <span></span>
                            </div>
                            <div className="inbox-item-content">
                                <p className="inbox-item-title">{message.title}</p>
                                <span className="inbox-item-msg">{messagePreview}</span>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="inbox-footer">
                {currentPage > 1 && (
                    <button onClick={() => setCurrentPage(currentPage - 1)}>Previous</button>
                )}
                {currentPage < totalPages && (
                    <button onClick={() => setCurrentPage(currentPage + 1)}>Next</button>
                )}
            </div>
            <MessageModal show={showModal} handleClose={() => setShowModal(false)} message={fullMessage} />
        </div>
    );
}

export default Inbox;
